import React from 'react';
import styled from 'styled-components';

export const ItemCountBadgeWrapper = styled.span`
  align-items: center;
  background-color: var(--primary-oqio-blue);
  border-radius: var(--border-radius-medium);
  color: var(--greyscale-white);
  display: flex;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  height: 20px;
  justify-content: center;
  line-height: var(--font-line-height-small);
  margin-left: 5px;
  min-width: 20px;
  padding: 2px 6px;
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 1;

  &.items-count {
    &-secondary {
      background-color: var(--greyscale-white);
      border: 1px solid var(--primary-oqio-blue);
      color: var(--primary-oqio-blue);
    }

    &-left {
      right: 149px;
    }

    &-worklist {
      border: 1px solid white;
      line-height: var(--font-line-height-small);
      right: 5px;
      top: 0;
    }
  }
`;

/**
 * The `ItemCountBadge` component is a React component that displays the count of items for a component.
 *
 * @param {string} className - An string that contains a class name for the component.
 * @param {number} count - A number that represents the count of items.
 * @returns {JSX.Element} - A React component that displays the count of items.
 */
export const ItemCountBadge = ({ className = '', count }) => {
  return count > 0 && <ItemCountBadgeWrapper className={className}>{count}</ItemCountBadgeWrapper>;
};
