const actions = {
  CREATE_HOUSING_STOCK: 'CREATE_HOUSING_STOCK',
  CREATE_HOUSING_STOCK_SUCCESS: 'CREATE_HOUSING_STOCK_SUCCESS',
  CREATE_HOUSING_STOCK_ERROR: 'CREATE_HOUSING_STOCK_ERROR',

  UPDATE_HOUSING_STOCK: 'UPDATE_HOUSING_STOCK',
  UPDATE_HOUSING_STOCK_SUCCESS: 'UPDATE_HOUSING_STOCK_SUCCESS',
  UPDATE_HOUSING_STOCK_ERROR: 'UPDATE_HOUSING_STOCK_ERROR',

  DELETE_HOUSING_STOCK: 'DELETE_HOUSING_STOCK',
  DELETE_HOUSING_STOCK_SUCCESS: 'DELETE_HOUSING_STOCK_SUCCESS',
  DELETE_HOUSING_STOCK_ERROR: 'DELETE_HOUSING_STOCK_ERROR',

  LOAD_HOUSING_STOCKS: 'LOAD_HOUSING_STOCKS',
  LOAD_HOUSING_STOCKS_SUCCESS: 'LOAD_HOUSING_STOCKS_SUCCESS',
  LOAD_HOUSING_STOCKS_ERROR: 'LOAD_HOUSING_STOCKS_ERROR',

  LOAD_CLAIMS_HOUSING_STOCKS: 'LOAD_CLAIMS_HOUSING_STOCKS',
  LOAD_CLAIMS_HOUSING_STOCKS_SUCCESS: 'LOAD_CLAIMS_HOUSING_STOCKS_SUCCESS',
  LOAD_CLAIMS_HOUSING_STOCKS_ERROR: 'LOAD_CLAIMS_HOUSING_STOCKS_ERROR',

  GET_POLICIES_HOUSING_STOCKS: 'GET_POLICIES_HOUSING_STOCKS',
  GET_POLICIES_HOUSING_STOCKS_SUCCESS: 'GET_POLICIES_HOUSING_STOCKS_SUCCESS',
  GET_POLICIES_HOUSING_STOCKS_ERROR: 'GET_POLICIES_HOUSING_STOCKS_ERROR',

  SET_HOUSING_STOCKS: 'SET_HOUSING_STOCKS',

  CLEAR_HOUSING_STOCKS: 'CLEAR_HOUSING_STOCKS',
  CLEAR_HOUSING_STOCKS_ERRORS: 'CLEAR_HOUSING_STOCKS_ERRORS',

  createHousingStock: (housingStock) => ({
    type: actions.CREATE_HOUSING_STOCK,
    housingStock
  }),
  createHousingStockSuccess: () => ({
    type: actions.CREATE_HOUSING_STOCK_SUCCESS
  }),
  createHousingStockError: () => ({
    type: actions.CREATE_HOUSING_STOCK_ERROR
  }),
  updateHousingStock: (housingStock) => ({
    type: actions.UPDATE_HOUSING_STOCK,
    housingStock
  }),
  updateHousingStockSuccess: () => ({
    type: actions.UPDATE_HOUSING_STOCK_SUCCESS
  }),
  updateHousingStockError: () => ({
    type: actions.UPDATE_HOUSING_STOCK_ERROR
  }),
  deleteHousingStock: (housingStockId) => ({
    type: actions.DELETE_HOUSING_STOCK,
    housingStockId
  }),
  deleteHousingStockSuccess: () => ({
    type: actions.DELETE_HOUSING_STOCK_SUCCESS
  }),
  deleteHousingStockError: () => ({
    type: actions.DELETE_HOUSING_STOCK_ERROR
  }),
  getHousingStocks: () => ({
    type: actions.LOAD_HOUSING_STOCKS
  }),
  getHousingStocksSuccess: (housingStocks) => ({
    type: actions.LOAD_HOUSING_STOCKS_SUCCESS,
    housingStocks
  }),
  getHousingStocksError: () => ({
    type: actions.LOAD_HOUSING_STOCKS_ERROR
  }),
  getClaimsHousingStocks: () => ({
    type: actions.LOAD_CLAIMS_HOUSING_STOCKS
  }),
  getClaimsHousingStocksSuccess: (housingStocks) => ({
    type: actions.LOAD_CLAIMS_HOUSING_STOCKS_SUCCESS,
    housingStocks
  }),
  getClaimsHousingStocksError: () => ({
    type: actions.LOAD_CLAIMS_HOUSING_STOCKS_ERROR
  }),
  getPoliciesHousingStocks: () => ({
    type: actions.GET_POLICIES_HOUSING_STOCKS
  }),
  getPoliciesHousingStocksSuccess: (housingStocks) => ({
    type: actions.GET_POLICIES_HOUSING_STOCKS_SUCCESS,
    housingStocks
  }),
  getPoliciesHousingStocksError: () => ({
    type: actions.GET_POLICIES_HOUSING_STOCKS_ERROR
  }),
  clearHousingStockErrors: () => ({
    type: actions.CLEAR_HOUSING_STOCKS_ERRORS
  })
};

export default actions;
