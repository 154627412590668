import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import checkUserRights from '../../helpers/checkUserUIRights';

/**
 * Functional component that checks user rights and conditionally renders a component or redirects.
 * @property {React.Component} component - The component to be rendered if access is granted.
 * @property {object} userRights - User's rights, fetched from the Redux store.
 * @property {Array} accessRights - Array of rights required to access the route.
 * @param {object} rest - Any additional props passed to the component.
 * @returns {JSX.Element|null} - Returns the component if access is allowed, otherwise redirects to the not-allowed page.
 */
const AuthorizedRouteComponent = ({ component: Component, userRights, accessRights, ...rest }) => {
  const navigate = useNavigate();

  // Memoize the result of checkUserRights to avoid redundant computations
  const isAuthorized = useMemo(() => checkUserRights(userRights, accessRights), [userRights, accessRights]);

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/notAllowed');
    }
  }, [isAuthorized, navigate]);

  // Render the component if the user has access rights, otherwise return null (user will be redirected in useEffect).
  return isAuthorized ? <Component {...rest} /> : null;
};

export const AuthorizedRoute = connect((state) => ({
  userRights: state.Auth.get('userRights')
}))(AuthorizedRouteComponent);
