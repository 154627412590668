const actions = {
  CREATE_POLICIES_TO_RISKS: 'CREATE_POLICIES_TO_RISKS',
  CREATE_POLICIES_TO_RISKS_ERROR: 'CREATE_POLICIES_TO_RISKS_ERROR',
  CREATE_POLICIES_TO_RISKS_SUCCESS: 'CREATE_POLICIES_TO_RISKS_SUCCESS',
  GET_POLICIES_TO_RISKS: 'GET_POLICIES_TO_RISKS',
  GET_POLICIES_TO_RISKS_ERROR: 'GET_POLICIES_TO_RISKS_ERROR',
  GET_POLICIES_TO_RISKS_SUCCESS: 'GET_POLICIES_TO_RISKS_SUCCESS',
  DELETE_MANY_POLICIES_TO_RISKS: 'DELETE_MANY_POLICIES_TO_RISKS',
  DELETE_MANY_POLICIES_TO_RISKS_ERROR: 'DELETE_MANY_POLICIES_TO_RISKS_ERROR',
  DELETE_MANY_POLICIES_TO_RISKS_SUCCESS: 'DELETE_MANY_POLICIES_TO_RISKS_SUCCESS',
  CLEAR_POLICIES_TO_RISKS: 'CLEAR_POLICIES_TO_RISKS',

  /**
   * Action to create a risk to policies relationship
   * @param {Array} params - An array of objects that each contain a risk and a policies id.
   * @param {boolean} showNotification - Whether or not to show a notification when a policiesToRisks is created.
   * @property {string} params.risk_id
   * @property {string} params.policy_id
   */
  createPoliciesToRisks: (params, showNotification) => ({
    type: actions.CREATE_POLICIES_TO_RISKS,
    params,
    showNotification
  }),
  createPoliciesToRisksError: () => ({
    type: actions.CREATE_POLICIES_TO_RISKS_ERROR
  }),
  createPoliciesToRisksSuccess: (payload) => ({
    type: actions.CREATE_POLICIES_TO_RISKS_SUCCESS,
    payload
  }),

  /**
   * @param {Object} params
   * @property {Array} params.riskId - array of risk ids
   * @property {Array} params.policiesId - array of policies ids
   * @property {Boolean} params.paging
   * @property {Number} params.page
   * @property {Number} params.page_size
   */
  getPoliciesToRisks: (params) => ({
    type: actions.GET_POLICIES_TO_RISKS,
    params
  }),
  getPoliciesToRisksError: () => ({
    type: actions.GET_POLICIES_TO_RISKS_ERROR
  }),
  getPoliciesToRisksSuccess: (payload) => ({
    type: actions.GET_POLICIES_TO_RISKS_SUCCESS,
    payload
  }),
  /**
   * @param {Array} params - Array of PoliciesToRisks ids to be deleted
   */
  deleteManyPoliciesToRisks: (params) => ({
    type: actions.DELETE_MANY_POLICIES_TO_RISKS,
    params
  }),
  deleteManyPoliciesToRisksError: () => ({
    type: actions.DELETE_MANY_POLICIES_TO_RISKS_ERROR
  }),
  deleteManyPoliciesToRisksSuccess: () => ({
    type: actions.DELETE_MANY_POLICIES_TO_RISKS_SUCCESS
  }),
  clearPoliciesToRisks: () => ({
    type: actions.CLEAR_POLICIES_TO_RISKS
  })
};

export default actions;
