import { all, takeEvery, put, call, select, fork } from 'redux-saga/effects';
import queryString from 'query-string';
import FileSaver from 'file-saver';
import { onGetRisks } from '../risks/apiCalls';
import authorizationActions from '../authorization/actions';
import insuredRisksActions from '../insuredRisks/actions';
import policiesToRisksActions from '../policiesToRisks/actions';
import renderNotification from '../../helpers/notifications/renderNotification';
import newEntityNotification from '../../helpers/notifications/newEntityNotification';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';
import { getFileNameFromApiResponseHeader } from '../../helpers/utility';
import { INSURANCE_ORGANIZATIONS } from '../../consts/consts';
import actions from './actions';
import {
  onLoadOne,
  onLoadAll,
  onCreatePolicy,
  onDeletePolicy,
  onLoadAuth,
  onUpdatePolicy,
  onLoadCustomValues,
  onDownloadImportTemplate,
  onImportPoliciesFile,
  onExportPolicies,
  onCreateInsuredRisksForPolicy,
  onUpdateInsuredRisksForPolicy,
  onDeleteInsuredRisksForPolicy,
  onLoadInsuredRisksPairs,
  onLoadPoliciesInsuredRiskTypes,
  onLoadPoliciesInsuredRisks,
  onGetPoliciesFiltersOrganizationsWithAccess,
  onGetPolicyOrganizationsWithAccess
} from './apiCalls';

export function* loadPoliciesWithoutPagination({ params }) {
  try {
    const response = yield call(onLoadAll, { ...params, paging: false });
    if (response.data) {
      yield put(actions.getAllPoliciesWithoutPaginationSuccess(response.data));
    } else {
      yield put(actions.getAllPoliciesWithoutPaginationError());
    }
  } catch {
    yield put(actions.getAllPoliciesWithoutPaginationError());
  }
}

export function* loadPolicies({ params }) {
  try {
    const response = yield call(onLoadAll, { ...params });
    if (response.data) {
      yield all([
        put(
          actions.loadPoliciesSuccess(response.data, {
            perPage: response.headers.perpage,
            total: response.headers.total
          })
        )
      ]);
    } else {
      yield put(actions.loadPoliciesError());
    }
  } catch {
    yield put(actions.loadPoliciesError());
  }
}

export function* loadPolicy({ payload }) {
  try {
    const response = yield call(onLoadOne, payload.policyId);
    if (response.data) {
      yield put(actions.getPolicySuccess(response.data));
    } else {
      yield put(actions.getPolicyError());
    }
  } catch {
    yield put(actions.getPolicyError());
  }
}

export function* getPolicyOrganizationsWithAccessSaga({ policyId }) {
  try {
    const response = yield call(onGetPolicyOrganizationsWithAccess, policyId);
    if (response.data) {
      yield put(actions.getPolicyOrganizationsWithAccessSuccess(response.data));
    } else {
      yield put(actions.getPolicyOrganizationsWithAccessError());
    }
  } catch {
    yield put(actions.getPolicyOrganizationsWithAccessError());
  }
}

export function* createPolicy({ policies, insuredRisksToAdd, authorizationsToAdd, linkedRisks, callback, navigate }) {
  try {
    const response = yield call(onCreatePolicy, policies);
    if (response.status === 200) {
      if (callback && typeof callback === 'function') {
        callback();
      }
      newEntityNotification({
        entityType: 'policy',
        entityId: response.data[0].id,
        navigate
      });
      yield put(actions.createPolicySuccess(response.data[0], insuredRisksToAdd, authorizationsToAdd, linkedRisks));
    } else {
      yield put(actions.createPolicyError());
    }
  } catch {
    yield put(actions.createPolicyError());
  }
}

export function* createPolicySuccess({ policy, insuredRisksToAdd, authorizationsToAdd, linkedRisks }) {
  try {
    const { id: policy_id } = policy;

    if (insuredRisksToAdd && insuredRisksToAdd.length > 0) {
      const insuredRisksToPolicy = insuredRisksToAdd.map((item) => ({ ...item, policy_id }));

      yield put(insuredRisksActions.addInsuredRisksToPolicy(insuredRisksToPolicy));
    }
    if (authorizationsToAdd && authorizationsToAdd.length > 0) {
      const authorizedOrganizations = authorizationsToAdd.map((organizationId) => ({
        policy_id,
        organization_id: organizationId
      }));

      yield put(authorizationActions.addAuthorizedOrganisation(authorizedOrganizations));
    }
    if (linkedRisks && linkedRisks.length > 0) {
      const PoliciesToRisks = linkedRisks.map((risk) => ({
        risk_id: risk.id,
        policy_id
      }));

      yield put(policiesToRisksActions.createPoliciesToRisks(PoliciesToRisks, false));
    }

    yield fork(reloadPolicies);
  } catch {
    yield put(actions.createPolicyError());
  }
}

export function* deletePolicies({ params, callback }) {
  try {
    const response = yield call(onDeletePolicy, params);
    if (response.status === 200) {
      if (callback && typeof callback === 'function') {
        callback();
      }
      const { policy_delete_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: policy_delete_success });
      yield put(actions.deletePoliciesSuccess());
    } else {
      yield put(actions.deletePoliciesError());
    }
  } catch {
    yield put(actions.deletePoliciesError());
  }
}

export function* loadAuth({ policyId }) {
  try {
    const response = yield call(onLoadAuth, policyId);
    if (response.data) {
      yield put(actions.getPolicyAuthSuccess(response.data));
    } else {
      yield put(actions.getPolicyAuthError());
    }
  } catch (error) {
    yield put(actions.getPolicyAuthError());
  }
}

export function* updatePolicy({ policy, callback }) {
  try {
    const response = yield call(onUpdatePolicy, [policy]);
    if (response.status === 200) {
      yield put(actions.updatePolicySuccess(response.data));
      if (callback && typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.updatePolicyError());
    }
  } catch {
    yield put(actions.updatePolicyError());
  }
}

export function* loadCustomValues({ insured_risk_type }) {
  try {
    const response = yield call(onLoadCustomValues, insured_risk_type);
    if (response.status === 200) {
      yield put(actions.getCustomValuesSuccess(response.data));
    } else {
      yield put(actions.getCustomValuesError());
    }
  } catch {
    yield put(actions.getCustomValuesError());
  }
}

export function* downloadTemplateFile() {
  try {
    const response = yield call(onDownloadImportTemplate);
    if (response.status === 200) {
      yield put(actions.downloadImportTemplateSuccess());

      const filename = getFileNameFromApiResponseHeader(response.headers, '.xlsx');

      if (window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const blob = new File([response.data], filename, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
        });
        FileSaver.saveAs(blob);
      }
    } else {
      yield put(actions.downloadImportTemplateError());
    }
  } catch {
    yield put(actions.downloadImportTemplateError());
  }
}

export function* uploadPoliciesFile({ file, housingStock }) {
  try {
    const response = yield call(onImportPoliciesFile, file, housingStock);
    if (response.status === 200) {
      yield put(actions.importPoliciesFileSuccess(response.data));
    } else {
      yield put(actions.importPoliciesFileError());
    }
  } catch {
    yield put(actions.importPoliciesFileError());
  }
}

export function* importPolicies({ policiesToCreate, policiesToUpdate, queryParams }) {
  const { policy_type } = queryParams;

  try {
    if (policiesToCreate.length > 0) {
      const createResponse = yield call(onCreatePolicy, policiesToCreate);

      yield put(actions.importCreatePolicies());

      if (createResponse.status === 200) {
        yield put(actions.importCreatePoliciesSuccess());
        yield put(actions.getCustomValues(policy_type));
      } else {
        yield put(actions.importCreatePoliciesError());
      }
    } else {
      yield put(actions.importCreatePoliciesSuccess());
    }

    if (policiesToUpdate.length > 0) {
      yield put(actions.importUpdatePolicies());
      const updateResponse = yield call(onUpdatePolicy, policiesToUpdate);

      if (updateResponse.status === 200) {
        yield put(actions.importUpdatePoliciesSuccess());
        yield put(actions.getCustomValues(policy_type));
      } else {
        yield put(actions.importUpdatePoliciesError());
      }
    } else {
      yield put(actions.importUpdatePoliciesSuccess());
    }

    yield put(actions.loadPolicies(queryParams));
  } catch {
    yield put(actions.importPoliciesError());
    yield put(actions.importCreatePoliciesError());
    yield put(actions.importUpdatePoliciesError());
  }
}

export function* exportPolicies({ params }) {
  try {
    const loadPoliciesResponse = yield call(onLoadAll, { ...params, paging: false });
    const policiesIds = loadPoliciesResponse.data.map((response) => response.id);
    const response = yield call(onExportPolicies, policiesIds);

    if (response.status === 200) {
      yield put(actions.exportPoliciesSuccess());

      const filename = getFileNameFromApiResponseHeader(response.headers, '.xlsx');

      // only IE does support this method
      if (window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const blob = new File([response.data], filename, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
        });
        FileSaver.saveAs(blob);
      }
    } else {
      yield put(actions.exportPoliciesError());
    }
  } catch {
    yield put(actions.exportPoliciesError());
  }
}

export function* bulkAssignInsuredRisksToPolicy({ selectedInsuredRisks, policies, insuredRisksToPolicies }) {
  const selectedPoliciesIds = policies.map((policy) => policy.id);

  try {
    const response = yield call(onLoadInsuredRisksPairs, selectedInsuredRisks);

    if (response.data) {
      const existingInsuredRisksPairs = response.data;
      const generatedPairs = insuredRisksToPolicies;
      const filteredInsuredRisksPairs = existingInsuredRisksPairs.filter((pair) =>
        selectedPoliciesIds.includes(pair.policy_id)
      );
      const insuredRisksPairsToUpdate = [];
      const insuredRisksPairsToCreate = generatedPairs.flat(Infinity).filter((generatedPair) => {
        const foundPair = filteredInsuredRisksPairs.find(
          (dataItem) =>
            dataItem.policy_id === generatedPair.policy_id && dataItem.insured_risk_id === generatedPair.insured_risk_id
        );
        if (foundPair) {
          insuredRisksPairsToUpdate.push({
            id: foundPair.id,
            ...generatedPair
          });
        }
        return !foundPair;
      });

      try {
        const responseOnCreate = yield call(onCreateInsuredRisksForPolicy, insuredRisksPairsToCreate);
        const responseOnUpdate = yield call(onUpdateInsuredRisksForPolicy, insuredRisksPairsToUpdate);

        if (responseOnCreate.status === 200 && responseOnUpdate.status === 200) {
          yield put(actions.bulkAssignInsuredRisksSuccess());
          const { policies_bulk_actions_add_insured_risks_success } = NOTIFICATION_MESSAGES;
          renderNotification({ type: 'success', message: policies_bulk_actions_add_insured_risks_success });
          return;
        }
        yield put(actions.bulkAssignInsuredRisksError());
      } catch {
        yield put(actions.bulkAssignInsuredRisksError());
      }
    } else {
      yield put(actions.bulkAssignInsuredRisksError());
    }
  } catch {
    yield put(actions.bulkAssignInsuredRisksError());
  }
}

export function* bulkUnassignInsuredRisksToPolicy({ insuredRisks, policies }) {
  try {
    const response = yield call(onLoadInsuredRisksPairs, insuredRisks);
    if (response.data) {
      const existingInsuredRisksPairs = response.data;
      const selectedPoliciesIds = policies.map((policy) => policy.id);
      const filteredInsuredRisksPairs = existingInsuredRisksPairs.filter((pair) =>
        selectedPoliciesIds.includes(pair.policy_id)
      );
      const filteredAuthorizationsIds = filteredInsuredRisksPairs.map((pair) => pair.id);

      try {
        const response = yield call(onDeleteInsuredRisksForPolicy, filteredAuthorizationsIds);
        if (response.status === 200) {
          yield put(actions.bulkUnassignInsuredRisksSuccess(response.data));
          const { policies_bulk_actions_remove_insured_risks_success } = NOTIFICATION_MESSAGES;
          renderNotification({ type: 'success', message: policies_bulk_actions_remove_insured_risks_success });
        } else {
          yield put(actions.bulkUnassignInsuredRisksError());
        }
      } catch {
        yield put(actions.bulkUnassignInsuredRisksError());
      }
    } else {
      yield put(actions.bulkUnassignInsuredRisksError());
    }
  } catch {
    yield put(actions.bulkUnassignInsuredRisksError());
  }
}

export function* getPolicyConnectedRisksSaga({ params }) {
  try {
    const response = yield call(onGetRisks, params);

    if (response.status === 200) {
      yield put(actions.getConnectedRisksSuccess(response.data));
    } else {
      yield put(actions.getConnectedRisksError());
    }
  } catch {
    yield put(actions.getConnectedRisksError());
  }
}

export function* getPoliciesInsuredRiskTypesSaga() {
  try {
    const response = yield call(onLoadPoliciesInsuredRiskTypes);

    if (response.status === 200) {
      yield put(actions.getPoliciesInsuredRiskTypesSuccess(response.data));
    } else {
      yield put(actions.getPoliciesInsuredRiskTypesError());
    }
  } catch {
    yield put(actions.getPoliciesInsuredRiskTypesError());
  }
}

export function* getPoliciesInsuredRisksSaga() {
  try {
    const response = yield call(onLoadPoliciesInsuredRisks);

    if (response.status === 200) {
      yield put(actions.getPoliciesInsuredRisksSuccess(response.data));
    } else {
      yield put(actions.getPoliciesInsuredRisksError());
    }
  } catch {
    yield put(actions.getPoliciesInsuredRisksError());
  }
}

export function* getPoliciesFiltersOrganizationsWithAccessSaga() {
  try {
    const response = yield call(onGetPoliciesFiltersOrganizationsWithAccess);

    if (response.status === 200) {
      // this returns an object with insurance organization id as key and logo as value
      const insuranceOrganizationLogos = response.data
        .map((org) => {
          if (org.type === 'insurance') {
            const normalizedOrgName = org.name.trim().toLowerCase();

            const matchingLogo = Object.keys(INSURANCE_ORGANIZATIONS).find((logoKey) =>
              INSURANCE_ORGANIZATIONS[logoKey].some((alias) => alias.trim().toLowerCase() === normalizedOrgName)
            );

            if (matchingLogo) {
              return {
                id: org.id,
                logo: matchingLogo
              };
            }
          }
          return null;
        })
        .filter((org) => org !== null)
        .reduce((accumulator, org) => {
          accumulator[org.id] = org.logo;
          return accumulator;
        }, {});

      yield all([
        put(actions.getPoliciesFiltersOrganizationsWithAccessSuccess(response.data)),
        put(actions.setInsuranceOrganizationLogos(insuranceOrganizationLogos))
      ]);
    } else {
      yield put(actions.getPoliciesFiltersOrganizationsWithAccessError());
    }
  } catch {
    yield put(actions.getPoliciesFiltersOrganizationsWithAccessError());
  }
}

const getActiveFilters = (state) => state.Filters.get('policiesActiveFilters');
const getSinglePolicy = (state) => state.Policies.get('policy');

export function* reloadPolicies() {
  const activeFilters = yield select(getActiveFilters);
  const parsedActiveFilters = queryString.parse(activeFilters);
  yield all([
    put(actions.loadPolicies(parsedActiveFilters)),
    // The filter options should be fetched on every policies reload,
    // as creating or editing policies might affect the filter options.
    put(actions.getPoliciesInsuredRisks()),
    put(actions.getPoliciesFiltersOrganizationsWithAccess()),
    put(actions.getPoliciesInsuredRiskTypes()),
    put(actions.getCustomValues())
  ]);
}

export function* reloadSinglePolicy() {
  const policy = yield select(getSinglePolicy);
  if (!policy) {
    return;
  }
  yield put(actions.getPolicy(policy.id));
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_POLICY, createPolicy),
    takeEvery(actions.CREATE_POLICY_SUCCESS, createPolicySuccess),
    takeEvery(actions.DELETE_POLICIES, deletePolicies),
    takeEvery(actions.LOAD_POLICY, loadPolicy),
    takeEvery(actions.GET_POLICY_ORGANIZATIONS_WITH_ACCESS, getPolicyOrganizationsWithAccessSaga),
    takeEvery(actions.LOAD_POLICIES_WITHOUT_PAGINATION, loadPoliciesWithoutPagination),
    takeEvery(actions.LOAD_POLICIES, loadPolicies),
    takeEvery(actions.LOAD_POLICY_AUTH, loadAuth),
    takeEvery(actions.UPDATE_POLICY, updatePolicy),
    takeEvery(actions.UPDATE_POLICY_SUCCESS, reloadSinglePolicy),
    takeEvery(actions.GET_CUSTOM_VALUES, loadCustomValues),
    takeEvery(actions.DOWNLOAD_IMPORT_TEMPLATE, downloadTemplateFile),
    takeEvery(actions.IMPORT_POLICIES_FILE, uploadPoliciesFile),
    takeEvery(actions.IMPORT_POLICIES, importPolicies),
    takeEvery(actions.EXPORT_POLICIES, exportPolicies),
    takeEvery(actions.BULK_ASSIGN_INSURED_RISKS, bulkAssignInsuredRisksToPolicy),
    takeEvery(actions.BULK_UNASSIGN_INSURED_RISKS, bulkUnassignInsuredRisksToPolicy),
    takeEvery(actions.BULK_ASSIGN_INSURED_RISKS_SUCCESS, reloadPolicies),
    takeEvery(actions.BULK_UNASSIGN_INSURED_RISKS_SUCCESS, reloadPolicies),
    takeEvery(actions.GET_CONNECTED_RISKS, getPolicyConnectedRisksSaga),
    takeEvery(actions.GET_POLICIES_INSURED_RISK_TYPES, getPoliciesInsuredRiskTypesSaga),
    takeEvery(actions.GET_POLICIES_INSURED_RISKS, getPoliciesInsuredRisksSaga),
    takeEvery(actions.GET_POLICIES_FILTERS_ORGANIZATIONS_WITH_ACCESS, getPoliciesFiltersOrganizationsWithAccessSaga)
  ]);
}
