import styled from 'styled-components';
import { windowMaxWidth } from '../breakpoints';

const WorklistListItemWrapper = styled.li`
  align-items: center;
  background-color: ${(props) => (props.$active ? 'rgba(24, 144, 255, .05)' : 'var(--greyscale-white)')};
  border-bottom: 1px solid var(--greyscale-dark-white);
  cursor: pointer;
  display: flex;
  height: 71px;
  justify-content: space-between;
  line-height: var(--font-line-height-small);
  padding: 10px 15px;
  transition: background 0.2s;

  &:last-child {
    margin-bottom: 40px;
  }

  &:hover {
    background-color: var(--greyscale-porcelain);
  }
`;

const WorklistListItemInfoWrapper = styled.div`
  align-items: top;
  display: flex;
  max-width: 300px;
`;

const WorklistListItemSecondaryInfoWrapper = styled.div`
  align-items: stretch;
  display: flex;
  height: 100%;
`;

const WorklistListItemIcon = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: 64px;
`;

const WorklistRiskItemIcon = styled.div`
  align-items: center;
  background: var(--greyscale-dark-white);
  border-radius: 50%;
  display: flex;
  height: 50px;
  justify-content: center;
  margin-right: 14px;
  position: relative;
  width: 50px;
`;

const WorklistListItemDate = styled.div`
  color: var(--greyscale-shade);
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-small);
  height: 100%;
  justify-content: flex-start;
  padding-bottom: 1px;
  padding-top: 1px;
  width: 110px;
`;

const WorklistListItemDateSubnote = styled.span``;

const WorklistListItemDescriptionType = styled.div`
  color: var(--greyscale-blackish);
  font-weight: var(--font-weight-medium);
`;

const WorklistListItemAddress = styled.div`
  color: var(--greyscale-shade);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 220px;

  @media ${windowMaxWidth.mobile} {
    width: auto;
    max-width: 120px;
  }
`;

const WorklistListItemIds = styled.div`
  color: var(--greyscale-shade);
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-small);
  height: 100%;
  justify-content: flex-start;
  padding-bottom: 1px;
  padding-top: 1px;
  width: 110px;

  @media ${windowMaxWidth.mobile} {
    display: none;
  }
`;

const WorklistListItemIdsItem = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 110px;
`;

const WorklistListItemIdsHousingStock = styled.div`
  color: var(--greyscale-blackish);
  font-weight: var(--font-weight-medium);
  height: var(--font-line-height-small);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 110px;
`;

const WorklistListItemRedirectIcon = styled.div`
  align-items: center;
  display: flex;
  font-size: var(--font-size-heading-3);
  margin-left: 10px;
`;

const WorklistListItemInsuredRisk = styled.div`
  font-weight: var(--font-weight-medium);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 220px;

  @media ${windowMaxWidth.mobile} {
    width: auto;
    max-width: 120px;
  }
`;

const WorklistListItemAddressDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-small);
  justify-content: flex-start;
  padding-bottom: 1px;
  padding-top: 1px;
`;

export const WorklistListItemLayout = {
  WorklistListItemWrapper,
  WorklistListItemInfoWrapper,
  WorklistListItemSecondaryInfoWrapper,
  WorklistListItemIcon,
  WorklistRiskItemIcon,
  WorklistListItemDate,
  WorklistListItemDateSubnote,
  WorklistListItemDescriptionType,
  WorklistListItemAddress,
  WorklistListItemIds,
  WorklistListItemIdsItem,
  WorklistListItemIdsHousingStock,
  WorklistListItemRedirectIcon,
  WorklistListItemInsuredRisk,
  WorklistListItemAddressDetails
};
