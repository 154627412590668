import { Map } from 'immutable';
import actions from './actions';

const initialState = new Map({
  risks: [],
  getRisksLoading: false,
  getRisksError: false,
  risk: null,
  getRiskLoading: false,
  getRiskError: false,
  allRisks: [],
  allRisksLoading: false,
  allRisksError: false,
  updateRiskLoading: false,
  updateRiskError: false,
  exportRisksLoading: false,
  exportRisksError: false,
  deleteRisksLoading: false,
  deleteRisksError: false,
  risksHousingStocks: [],
  risksHousingStocksLoading: false,
  risksHousingStocksError: false,
  risksCompanyCodes: [],
  risksCompanyCodesLoading: false,
  risksCompanyCodesError: false,
  risksEconomicUnits: [],
  risksEconomicUnitsLoading: false,
  risksEconomicUnitsError: false,
  createRisksLoading: false,
  createRisksError: false,
  totalRisks: 0,
  connectedPolicies: [],
  connectedPoliciesLoading: false,
  connectedPoliciesError: false,
  lastVisitedRiskId: null
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_RISKS:
      return state.set('getRisksLoading', true).set('getRisksError', false);
    case actions.GET_RISKS_SUCCESS:
      return state
        .set('getRisksLoading', false)
        .set('getRisksError', false)
        .set('risks', action.payload)
        .set('totalRisks', parseInt(action.meta?.total, 10));
    case actions.GET_RISKS_ERROR:
      return state.set('getRisksLoading', false).set('getRisksError', true);
    case actions.GET_RISK:
      return state.set('getRiskLoading', true).set('getRiskError', false);
    case actions.GET_RISK_SUCCESS:
      return state.set('getRiskLoading', false).set('getRiskError', false).set('risk', action.payload);
    case actions.GET_RISK_ERROR:
      return state.set('getRiskLoading', false).set('getRiskError', true);
    case actions.GET_RISK_WITH_MESSAGES:
      return state.set('getRiskLoading', true).set('getRiskError', false);
    case actions.GET_RISK_WITH_MESSAGES_SUCCESS:
      return state.set('getRiskLoading', false).set('getRiskError', false).set('risk', action.payload);
    case actions.GET_RISK_WITH_MESSAGES_ERROR:
      return state.set('getRiskLoading', false).set('getRiskError', true);
    case actions.GET_ALL_RISKS:
      return state.set('allRisksLoading', true).set('allRisksError', false);
    case actions.GET_ALL_RISKS_SUCCESS:
      return state.set('allRisksLoading', false).set('allRisksError', false).set('allRisks', action.payload);
    case actions.GET_ALL_RISKS_ERROR:
      return state.set('allRisksLoading', false).set('allRisksError', true);
    case actions.UPDATE_RISKS:
      return state.set('updateRiskLoading', true).set('updateRiskError', false);
    case actions.UPDATE_RISKS_SUCCESS:
      return state.set('updateRiskLoading', false).set('updateRiskError', false);
    case actions.UPDATE_RISKS_ERROR:
      return state.set('updateRiskLoading', false).set('updateRiskError', true);
    case actions.EXPORT_RISKS:
      return state.set('exportRisksLoading', true);
    case actions.EXPORT_RISKS_SUCCESS:
      return state.set('exportRisksLoading', false);
    case actions.EXPORT_RISKS_ERROR:
      return state.set('exportRisksLoading', false).set('exportRisksError', true);
    case actions.DELETE_RISKS:
      return state.set('deleteRisksLoading', true).set('deleteRisksError', false);
    case actions.DELETE_RISKS_ERROR:
      return state.set('deleteRisksLoading', false).set('deleteRisksError', true);
    case actions.DELETE_RISKS_SUCCESS:
      return state.set('deleteRisksLoading', false).set('deleteRisksError', false);
    case actions.RESET_RISKS:
      return state.set('risks', []);
    case actions.RESET_ALL_RISKS:
      return state.set('allRisks', []);
    case actions.GET_RISKS_HOUSING_STOCKS:
      return state.set('risksHousingStocksLoading', true).set('risksHousingStocksError', false);
    case actions.GET_RISKS_HOUSING_STOCKS_SUCCESS:
      return state
        .set('risksHousingStocksLoading', false)
        .set('risksHousingStocksError', false)
        .set('risksHousingStocks', action.payload);
    case actions.GET_RISKS_HOUSING_STOCKS_ERROR:
      return state.set('risksHousingStocksLoading', false).set('risksHousingStocksError', true);
    case actions.GET_RISKS_COMPANY_CODES:
      return state.set('risksCompanyCodesLoading', true).set('risksCompanyCodesError', false);
    case actions.GET_RISKS_COMPANY_CODES_SUCCESS:
      return state
        .set('risksCompanyCodesLoading', false)
        .set('risksCompanyCodesError', false)
        .set('risksCompanyCodes', action.payload);
    case actions.GET_RISKS_COMPANY_CODES_ERROR:
      return state.set('risksCompanyCodesLoading', false).set('risksCompanyCodesError', true);
    case actions.GET_RISKS_ECONOMIC_UNITS:
      return state.set('risksEconomicUnitsLoading', true).set('risksEconomicUnitsError', false);
    case actions.GET_RISKS_ECONOMIC_UNITS_SUCCESS:
      return state
        .set('risksEconomicUnitsLoading', false)
        .set('risksEconomicUnitsError', false)
        .set('risksEconomicUnits', action.payload);
    case actions.GET_RISKS_ECONOMIC_UNITS_ERROR:
      return state.set('risksEconomicUnitsLoading', false).set('risksEconomicUnitsError', true);
    case actions.CREATE_RISKS:
      return state.set('createRisksLoading', true).set('createRisksError', false);
    case actions.CREATE_RISKS_SUCCESS:
      return state.set('createRisksLoading', false).set('createRisksError', false);
    case actions.CREATE_RISKS_ERROR:
      return state.set('createRisksLoading', false).set('createRisksError', true);
    case actions.CLEAR_SINGLE_RISK:
      return state.set('risk', null);
    case actions.GET_CONNECTED_POLICIES:
      return state.set('connectedPoliciesLoading', true).set('connectedPoliciesError', false);
    case actions.GET_CONNECTED_POLICIES_SUCCESS:
      return state
        .set('connectedPoliciesLoading', false)
        .set('connectedPoliciesError', false)
        .set('connectedPolicies', action.payload);
    case actions.GET_CONNECTED_POLICIES_ERROR:
      return state.set('connectedPoliciesLoading', false).set('connectedPoliciesError', true);
    case actions.CLEAR_CONNECTED_POLICIES:
      return state.set('connectedPolicies', []);
    case actions.SET_LAST_VISITED_RISK_ID:
      return state.set('lastVisitedRiskId', action.riskId);
    case actions.RESET_LAST_VISITED_RISK_ID:
      return state.set('lastVisitedRiskId', null);
    default:
      return state;
  }
};

export default reducer;
