import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  sidebarLocation: 'messagesListView',
  sidebarDefaultLocation: 'messagesListView'
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_SIDEBAR_LOCATION:
      return state.set('sidebarLocation', action.value);
    case actions.SET_SIDEBAR_DEFAULT_LOCATION:
      return state.set('sidebarDefaultLocation', action.value);
    default:
      return state;
  }
};

export default reducer;
