import React from 'react';
import AntInput from 'antd/es/input';
import styled from 'styled-components';

const { TextArea } = AntInput;

const ExtendedTextarea = styled(TextArea)`
  &.ant-input {
    background-color: var(--greyscale-porcelain);
    background-image: none;
    border: 1px solid var(--greyscale-concrete);
    border-radius: var(--border-radius-small);
    color: var(--greyscale-blackish);
    font-size: var(--font-size-regular);
    cursor: text;
    height: auto;
    min-height: 95px;
    line-height: 1.5;
    padding: 4px 10px;
    width: 100%;
    resize: ${(props) => (props.$enableResize ? 'vertical' : 'none')};

    &:focus {
      border: 1px solid var(--primary-oqio-blue);
      box-shadow: var(--box-shadow-oqio-blue);
    }

    &:hover:not(.ant-input-disabled) {
      border: 1px solid var(--primary-oqio-blue);
    }

    &.worklist-message-reminder-modal {
      background-color: var(--greyscale-white);
    }

    &.ant-input-status-error {
      box-shadow: var(--box-shadow-oqio-red);

      &:hover,
      &:focus {
        box-shadow: var(--box-shadow-oqio-blue);
      }
    }
  }

  &.ant-input-disabled {
    background-color: var(--greyscale-porcelain);
    border: 1px solid var(--greyscale-dark-white);
    color: var(--greyscale-concrete) !important;
    cursor: default;

    &:hover {
      border: 1px solid var(--greyscale-dark-white);
    }
  }
`;

export const Textarea = (props) => <ExtendedTextarea {...props} maxLength={props.maxLength || 10000} />;
