import React, { lazy } from 'react';
import { Routes, Route, Navigate, useLocation, useParams } from 'react-router';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Layout } from 'antd';
import Header from '../../components/Headers/Header';
import { AuthorizedRoute } from '../../components/Auth/AuthorizedRoute';
import withSuspense from '../../hoc/withSuspense';
import IntercomController from '../Chat/IntercomContainer';
import MainWrapper from './MainWrapper.style';

const AdministrationContainer = withSuspense(lazy(() => import('../Administration/AdministrationContainer')));
const RisksContainer = withSuspense(lazy(() => import('../Risks/RisksContainer')));
const RiskDetails = withSuspense(lazy(() => import('../../components/RiskDetails/RiskDetails')));
const ClaimDetails = withSuspense(lazy(() => import('../../components/ClaimDetails/ClaimDetails')));
const ClaimsContainer = withSuspense(lazy(() => import('../Claims/ClaimsContainer')));
const ReportingContainer = withSuspense(lazy(() => import('../Reporting/ReportingContainer')));
const PoliciesContainer = withSuspense(lazy(() => import('../Policies/PoliciesContainer')));
const PolicyDetails = withSuspense(lazy(() => import('../../components/PolicyDetails/PolicyDetails')));
const SidebarContainer = withSuspense(lazy(() => import('../Sidebar/SidebarContainer')));

const RedirectToClaimDetailsMainTab = () => {
  const { claimId } = useParams();
  return <Navigate to={`/claim/${claimId}/main`} replace />;
};

/**
 * Functional component that sets up the main application routes.
 * It includes lazy-loaded components and route handling for different parts of the application.
 * @property {Object} user - the current logged-in user information.
 */
const AppRouter = ({ user }) => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  return (
    <MainWrapper>
      <IntercomController user={user}>
        <Layout className="page-with-header" id="page-modal-wrapper">
          <Header />
          <Layout.Content className="page-wrapper">
            {<SidebarContainer />}
            <div className="page-content">
              <Routes>
                {/* Home redirects to claims list */}
                <Route path="/" element={<Navigate to="/claims/list" replace />} />
                <Route
                  path="claims/:tab"
                  element={<AuthorizedRoute component={ClaimsContainer} queryParams={queryParams} />}
                />

                {/* Claim details*/}
                <Route path="claim/:claimId" element={<AuthorizedRoute component={RedirectToClaimDetailsMainTab} />} />
                <Route
                  path="claim/:claimId/:tab?/:settlementId?"
                  element={<AuthorizedRoute component={ClaimDetails} />}
                />

                {/* Risks list */}
                <Route path="buildings/*" element={<Navigate to="/risks/list" replace />} />
                <Route
                  path="risks/:view"
                  element={
                    <AuthorizedRoute component={RisksContainer} accessRights={['risks_ui']} queryParams={queryParams} />
                  }
                />

                {/* Risk details */}
                <Route
                  path="risk/:riskId/:tab"
                  element={
                    <AuthorizedRoute component={RiskDetails} accessRights={['risks_ui']} queryParams={queryParams} />
                  }
                />

                {/* Policies list */}
                <Route
                  path="policies/:view"
                  element={
                    <AuthorizedRoute
                      component={PoliciesContainer}
                      accessRights={['policies_ui']}
                      queryParams={queryParams}
                    />
                  }
                />

                {/* Policy details */}
                <Route
                  path="policy/:policyId/:tab"
                  element={
                    <AuthorizedRoute
                      component={PolicyDetails}
                      accessRights={['policies_ui']}
                      queryParams={queryParams}
                    />
                  }
                />

                {/* Reporting */}
                <Route
                  path="reporting/*"
                  element={
                    <AuthorizedRoute
                      component={ReportingContainer}
                      queryParams={queryParams}
                      accessRights={['reporting_ui']}
                    />
                  }
                />

                {/* Administration */}
                <Route
                  path="administration/*"
                  element={<AuthorizedRoute component={AdministrationContainer} accessRights={['administration_ui']} />}
                />

                {/* Page not found */}
                <Route path="*" element={<Navigate to="/pageNotFound" replace />} />
              </Routes>
            </div>
          </Layout.Content>
        </Layout>
      </IntercomController>
    </MainWrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.Auth.get('user')
});

export default connect(mapStateToProps)(AppRouter);
