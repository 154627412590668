import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  worklistRisks: [],
  worklistRisksLoading: false,
  worklistRisksError: false,
  worklistRiskMessages: null,
  worklistRiskMessagesLoading: false,
  worklistRiskMessagesError: false,
  createWorklistRiskMessageLoading: false,
  createWorklistRiskMessageError: false,
  updateWorklistRiskMessageLoading: false,
  updateWorklistRiskMessageError: false,
  messageRecipientOrganizations: [],
  messageRecipientOrganizationsLoading: false,
  messageRecipientOrganizationsError: false,
  riskActiveMessagesCount: 0,
  riskActiveMessagesCountLoading: false,
  riskActiveMessagesCountError: false,
  risksListActiveMessagesCount: 0,
  risksListActiveMessagesCountLoading: false,
  risksListActiveMessagesCountError: false,
  worklistHeaderActiveMessagesCount: 0,
  worklistRiskMessagesFilter: '',
  worklistRiskMessagesDefaultFilter: '',
  worklistRiskMessagesFilterIsChanged: false,
  worklistRiskMessagesFilterIsOpen: false,
  isCreateMessageTouched: false,
  newMessageAttachments: [],
  isNewMessageAttachmentsUploadLoading: false
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_WORKLIST_RISKS:
      return state.set('worklistRisksLoading', true);
    case actions.GET_WORKLIST_RISKS_SUCCESS:
      return state.set('worklistRisks', action.risks).set('worklistRisksLoading', false);
    case actions.GET_WORKLIST_RISKS_ERROR:
      return state.set('worklistRisksError', true).set('worklistRisksLoading', false);
    case actions.GET_WORKLIST_RISK_MESSAGES:
      return state.set('worklistRiskMessagesLoading', true).set('worklistHeaderActiveMessagesCount', null);
    case actions.GET_WORKLIST_RISK_MESSAGES_SUCCESS:
      return state.set('worklistRiskMessages', action.messages).set('worklistRiskMessagesLoading', false);
    case actions.GET_WORKLIST_RISK_MESSAGES_ERROR:
      return state.set('worklistRiskMessagesError', true).set('worklistRiskMessagesLoading', false);
    case actions.GET_RISK_MESSAGE_RECIPIENT_ORGANIZATIONS:
      return state.set('messageRecipientOrganizationsLoading', true).set('messageRecipientOrganizationsError', false);
    case actions.GET_RISK_MESSAGE_RECIPIENT_ORGANIZATIONS_SUCCESS:
      return state
        .set('messageRecipientOrganizations', action.organizations)
        .set('messageRecipientOrganizationsLoading', false)
        .set('messageRecipientOrganizationsError', false);
    case actions.GET_RISK_MESSAGE_RECIPIENT_ORGANIZATIONS_ERROR:
      return state.set('messageRecipientOrganizationsLoading', false).set('messageRecipientOrganizationsError', true);
    case actions.GET_RISK_ACTIVE_MESSAGES_COUNT:
      return state.set('riskActiveMessagesCountLoading', true);
    case actions.GET_RISK_ACTIVE_MESSAGES_COUNT_SUCCESS:
      return state
        .set('riskActiveMessagesCount', action.count)
        .set('riskActiveMessagesCountLoading', false)
        .set('riskActiveMessagesCountError', false);
    case actions.GET_RISK_ACTIVE_MESSAGES_COUNT_ERROR:
      return state.set('riskActiveMessagesCountError', true).set('riskActiveMessagesCountLoading', false);
    case actions.GET_RISKS_LIST_ACTIVE_MESSAGES_COUNT:
      return state.set('risksListActiveMessagesCountLoading', true);
    case actions.GET_RISKS_LIST_ACTIVE_MESSAGES_COUNT_SUCCESS:
      return state.set('risksListActiveMessagesCount', action.count).set('risksListActiveMessagesCountLoading', false);
    case actions.GET_RISKS_LIST_ACTIVE_MESSAGES_COUNT_ERROR:
      return state.set('risksListActiveMessagesCountError', true).set('risksListActiveMessagesCountLoading', false);
    case actions.SET_WORKLIST_HEADER_ACTIVE_MESSAGES_COUNT:
      return state.set('worklistHeaderActiveMessagesCount', action.count);
    case actions.CREATE_WORKLIST_RISK_MESSAGE:
      return state.set('createWorklistRiskMessageLoading', true).set('createWorklistRiskMessageError', false);
    case actions.CREATE_WORKLIST_RISK_MESSAGE_SUCCESS:
      return state.set('createWorklistRiskMessageLoading', false);
    case actions.CREATE_WORKLIST_RISK_MESSAGE_ERROR:
      return state.set('createWorklistRiskMessageLoading', false).set('createWorklistRiskMessageError', true);
    case actions.SET_WORKLIST_RISK_MESSAGES_FILTER:
      return state.set('worklistRiskMessagesFilter', action.filter).set('worklistRiskMessagesFilterIsChanged', true);
    case actions.SET_WORKLIST_RISK_MESSAGES_FILTER_TO_DEFAULT:
      return state
        .set('worklistRiskMessagesFilter', state.get('worklistRiskMessagesDefaultFilter'))
        .set('worklistRiskMessagesFilterIsChanged', false);
    case actions.UPDATE_WORKLIST_RISK_MESSAGE:
      return state.set('worklistRiskMessagesFilterIsChanged', false).set('updateWorklistRiskMessageLoading', true);
    case actions.UPDATE_WORKLIST_RISK_MESSAGE_SUCCESS:
      return state.set('updateWorklistRiskMessageLoading', false);
    case actions.UPDATE_WORKLIST_RISK_MESSAGE_ERROR:
      return state.set('updateWorklistRiskMessageError', true).set('updateWorklistRiskMessageLoading', false);
    case actions.CLEAR_WORKLIST_RISK_MESSAGES:
      return state.set('worklistRiskMessages', null);
    case actions.SET_IS_CREATE_MESSAGE_TOUCHED:
      return state.set('isCreateMessageTouched', action.isTouched);
    case actions.CLEAR_IS_CREATE_MESSAGE_TOUCHED:
      return state.set('isCreateMessageTouched', false);
    case actions.SET_NEW_MESSAGE_ATTACHMENTS:
      return state.set('newMessageAttachments', action.attachments);
    case actions.CLEAR_NEW_MESSAGE_ATTACHMENTS:
      return state.set('newMessageAttachments', []);
    case actions.SET_IS_NEW_MESSAGE_ATTACHMENTS_UPLOAD_LOADING:
      return state.set('isNewMessageAttachmentsUploadLoading', action.isLoading);
    case actions.OPEN_WORKLIST_RISK_MESSAGES_FILTER:
      return state.set('worklistRiskMessagesFilterIsOpen', true);
    case actions.CLOSE_WORKLIST_RISK_MESSAGES_FILTER:
      return state.set('worklistRiskMessagesFilterIsOpen', false);
    default:
      return state;
  }
};

export default reducer;
