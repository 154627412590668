import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import App from './App';
import './variables.css';
import 'antd/dist/reset.css';
import './global.css';
import '@ant-design/v5-patch-for-react-19';

const container = document.getElementById('root');
const root = createRoot(container); // Create a root

root.render(<App />); // Use the root to render

if (module.hot) {
  module.hot.accept('./App.js', () => {
    root.render(<App />); // Use the root to render in hot module replacement
  });
}
