import _axios from 'axios';
import qs from 'query-string';
import { store } from '../redux/store';
import authActions from '../redux/auth/actions';
import renderNotification from '../helpers/notifications/renderNotification';
import { getStatusText } from '../helpers/httpStatus';
import { NOTIFICATION_MESSAGES } from '../localization/Notifications/dictionary';
import { BASE_URL } from './base';

const axios = _axios.create({
  baseURL: BASE_URL,
  timeout: 600000,
  paramsSerializer: (params) => qs.stringify(params),
  headers: {
    'Oqio-Client-Version': '1.3.2b',
    Pragma: 'no-cache'
  }
});

axios.defaults.withCredentials = true;
axios.defaults.headers.Pragma = 'no-cache';
axios.CancelToken = _axios.CancelToken;
axios.isCancel = _axios.isCancel;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // These are errors that should not show a notification to the user
    if (axios.isCancel(error) || error?.response?.status === 401) {
      // If we receive an unauthorized error, we trigger a validate user error action
      if (error?.response?.status === 401) {
        const { validateUserError } = authActions;
        store.dispatch(validateUserError());
      }
      return true;
    }

    let errorMessage = null;

    if (error?.response) {
      const { response } = error;
      errorMessage = response.data?.message ? response.data.message : getStatusText(response.status);

      if (response.data && response.data.errors) {
        if (Object.keys(response.data.errors).length) {
          errorMessage += ': \n';
        }

        Object.keys(response.data.errors).forEach((key) => {
          errorMessage += `${response.data.errors[key]}\n`;
        });
      }
    } else if (error?.request) {
      /* 
      The request was made but no response was received.
      `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      */
      errorMessage = 'No response for the request';
    } else {
      // Something happened in setting up the request that triggered an Error
      errorMessage = `Error making the request: ${error.message}`;
    }

    if (errorMessage) {
      // TODO: clarify this part and add error notification to separate component
      if (
        error?.response?.config &&
        (error.response.config.url.includes('api/v1/sessions/validate') ||
          error.response.config.url.match(/(.*)\/claims\/(.*)\/status(.*)/))
      ) {
        // eslint-disable-next-line no-console
        console.error('error from axios', errorMessage);
      } else {
        return renderNotification({
          type: 'error',
          message: NOTIFICATION_MESSAGES[errorMessage] || errorMessage
        });
      }
    }

    return Promise.reject(errorMessage);
  }
);

export default axios;
