import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import authorizationsSagas from './authorization/saga';
import claimSagas from './claims/saga';
import claimAuthorizationsSagas from './claimAuthorizations/saga';
import configSagas from './config/saga';
import fileUploadSagas from './fileUpload/saga';
import insuredRisksSaga from './insuredRisks/saga';
import kpisSagas from './kpis/saga';
import policySagas from './policies/saga';
import setllementSagas from './settlements/saga';
import usersSagas from './users/saga';
import organizationsSagas from './organizations/saga';
import ducumentFetchingSagas from './documentFetching/saga';
import housingStocksSagas from './housingStocks/saga';
import risksSagas from './risks/saga';
import policiesToRisksSagas from './policiesToRisks/saga';
import worklistClaimsSagas from './worklistClaims/saga';
import worklistRisksSagas from './worklistRisks/saga';
import sidebarSagas from './sidebar/saga';
import layoutSagas from './layout/saga';
import importSaga from './import/saga';
import fileContainersSaga from './fileContainers/saga';
import stripeSaga from './stripe/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    authorizationsSagas(),
    risksSagas(),
    policiesToRisksSagas(),
    claimSagas(),
    claimAuthorizationsSagas(),
    configSagas(),
    fileUploadSagas(),
    insuredRisksSaga(),
    kpisSagas(),
    policySagas(),
    setllementSagas(),
    organizationsSagas(),
    ducumentFetchingSagas(),
    usersSagas(),
    housingStocksSagas(),
    worklistClaimsSagas(),
    worklistRisksSagas(),
    sidebarSagas(),
    layoutSagas(),
    importSaga(),
    fileContainersSaga(),
    stripeSaga()
  ]);
}
