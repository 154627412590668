import styled from 'styled-components';
import { Heading3 } from '../Heading/Heading';
import { windowMaxWidth } from './breakpoints';

// Styles used for the design of the Details Navigation

const DetailsTabNavigationWrapper = styled.div`
  background-color: var(--greyscale-white);
  border-bottom: 1px solid var(--greyscale-dark-white);
  display: flex;
  flex-direction: row;
  padding: 0 20px;

  &.skeleton {
    border-bottom: 1px solid var(--greyscale-dark-white);
  }

  @media ${windowMaxWidth.mobile} {
    flex-direction: column;
  }
`;

const DetailsTabNavigationItem = styled.div`
  align-items: center;
  border-bottom: 2px solid ${(props) => (props.active ? 'var(--greyscale-blackish)' : 'transparent')};
  color: ${(props) => (props.active ? 'var(--greyscale-blackish)' : 'var(--greyscale-shade)')};
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-regular);
  font-weight: ${(props) => (props.active ? 'var(--font-weight-medium)' : 'var(--font-weight-regular)')};
  height: 40px;
  line-height: var(--font-line-height-regular);
  margin: 0 10px;
  margin-bottom: -1px;
  padding: 0 5px;

  @media ${windowMaxWidth.mobile} {
    display: none;
  }
`;

// Styles used for the Wrappers

const DetailsTabContentWrapper = styled.div`
  height: calc(100% - ${(props) => (props.$hasBanner ? '80px' : '40px')});
  overflow: auto;
  padding-bottom: ${(props) => (props.noPaddingBottom ? '0' : '20px')};
  padding-top: ${(props) => (props.noPaddingTop ? '0' : '20px')};
  scrollbar-color: rgba(136 136 136 / 30%) transparent;
  scrollbar-width: thin;

  @media ${windowMaxWidth.mobile} {
    height: auto;
  }
`;

const DetailsTabSectionWrapper = styled.div`
  padding: 0 30px;

  @media ${windowMaxWidth.mobile} {
    padding: 0 20px;
  }
`;

// Styles used for a List

const DetailsTabList = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => (props.$noPaddingBottom ? '0' : '20px')};
`;

const DetailsTabListItemWrapper = styled.div`
  border-bottom: 1px solid var(--greyscale-dark-white);
  margin-bottom: 20px;
`;

const DetailsTabListOneLineItemContentWrapper = styled.span`
  font-weight: var(--font-weight-regular);
  max-width: 65%;
  min-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media ${windowMaxWidth.mobile} {
    white-space: normal;
    overflow: auto;
    max-width: 100%;
  }
`;

const DetailsTabEmptyListWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
  padding-top: ${(props) => (props.$noPaddingTop ? '0' : '20px')};
`;

const DetailsTabEmptyListText = styled.div`
  color: var(--greyscale-shade);
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  margin-bottom: 10px;
  text-align: center;
  width: 350px;
`;

const DetailsTabEmptyListArrow = styled.div`
  color: var(--greyscale-shade);
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  margin-bottom: 0;
`;

// Styles used for a Header Section

const DetailsTabHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: ${(props) => (props.$buttonPosition === 'line-end' ? 'space-between' : 'flex-start')};
  margin-bottom: 10px;
  padding: 0 30px;

  div {
    button {
      &.ant-btn {
        padding: 0;
      }
    }
  }

  @media ${windowMaxWidth.mobile} {
    justify-content: space-between;
    padding: 0 20px;
  }

  &.policy-details-claims-list-header {
    @media ${windowMaxWidth.mobile} {
      h3 {
        display: none;
      }

      .ant-input-search {
        width: 100%;
      }

      div {
        margin: 0;
        width: 100%;
      }
    }
  }
`;

const DetailsTabHeaderSettlements = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 30px;

  div {
    button {
      &.ant-btn {
        padding: 0;
      }
    }
  }
`;

const DetailsTabHeaderTitle = styled(Heading3)`
  margin: 0 20px 0 0;
  max-width: ${(props) => (props.$longText ? 'none' : '220px')};
  min-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media ${windowMaxWidth.mobile} {
    min-width: auto;
  }
`;

const DetailsTabHeaderActionButtons = styled.div`
  align-items: center;
  display: flex;

  &.margin-auto {
    margin-left: auto;
  }
`;

// Styles used for a Message Wrapper
const DetailsTabAddNoteFormWrapper = styled.div`
  margin-bottom: 20px;
  margin-left: 240px;
  padding: 0 60px 0 30px;

  @media ${windowMaxWidth.mobile} {
    padding: 0 20px;
    margin-left: 0;
  }
`;

const DetailsTabAddNoteFormActionButtons = styled.div`
  display: flex;
  gap: 10px;
`;

// Styles used for an Overview Table

const DetailsTabOverviewTable = styled.div`
  margin-bottom: ${(props) => (props.$hasMarginBottom ? '20px' : '0')};
  padding: ${(props) => (props.$hasPaddingLeftRight ? '0 30px' : '0')};

  @media ${windowMaxWidth.mobile} {
    padding: ${(props) => (props.$hasPaddingLeftRight ? '0 20px' : '0')};
  }
`;

const DetailsTabOverviewTableRow = styled.div`
  display: flex;
  margin-bottom: 10px;

  &:last-child {
    margin: 0;
  }

  @media ${windowMaxWidth.mobile} {
    flex-direction: column;
  }
`;

const DetailsTabOverviewTableRowKey = styled.div`
  align-items: flex-starts;
  color: var(--greyscale-shade);
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
  gap: 10px;
  justify-content: flex-start;
  line-height: var(--font-line-height-regular);
  padding-right: 20px;
  width: 240px;

  svg {
    path {
      stroke: var(--greyscale-shade);
    }
  }
`;

const DetailsTabOverviewTableRowValue = styled.div`
  color: var(--greyscale-blackish);
  flex: 1;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  max-width: 80ch;
`;

const DetailsTabOverviewTableRowKeyLabel = styled.span``;

const DetailsTabOverviewTableRowKeyLabelIcon = styled.div`
  height: 20px;
  width: 20px;
`;

const DetailsTabOverviewTableRowValueLabel = styled.span`
  color: ${(props) => (props.$placeholder ? 'var(--greyscale-concrete)' : 'var(--greyscale-blackish)')};
  overflow-wrap: anywhere;
  white-space: pre-line;
`;

// Styles used for the Sticky Button at the bottom

const DetailsTabButtonWrapperOverlay = styled.div`
  background: linear-gradient(180deg, rgba(255 255 255 / 0%) 0%, var(--greyscale-white) 100%);
  border-radius: var(--border-radius-regular);
  bottom: 0;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  position: sticky;
  width: 100%;
`;

const DetailsTabFileContainerWrapper = styled.div`
  padding: 0 30px 10px;

  &:not(:first-child) {
    margin-top: 20px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--greyscale-dark-white);
  }

  @media ${windowMaxWidth.mobile} {
    padding: 0 20px 10px;
  }
`;

const DetailsTabLayout = {
  DetailsTabNavigationWrapper,
  DetailsTabList,
  DetailsTabListItemWrapper,
  DetailsTabSectionWrapper,
  DetailsTabEmptyListWrapper,
  DetailsTabEmptyListText,
  DetailsTabEmptyListArrow,
  DetailsTabNavigationItem,
  DetailsTabContentWrapper,
  DetailsTabHeader,
  DetailsTabHeaderSettlements,
  DetailsTabHeaderTitle,
  DetailsTabHeaderActionButtons,
  DetailsTabAddNoteFormWrapper,
  DetailsTabAddNoteFormActionButtons,
  DetailsTabOverviewTable,
  DetailsTabOverviewTableRow,
  DetailsTabOverviewTableRowKey,
  DetailsTabOverviewTableRowValue,
  DetailsTabOverviewTableRowKeyLabel,
  DetailsTabOverviewTableRowKeyLabelIcon,
  DetailsTabOverviewTableRowValueLabel,
  DetailsTabListOneLineItemContentWrapper,
  DetailsTabButtonWrapperOverlay,
  DetailsTabFileContainerWrapper
};

export default DetailsTabLayout;
