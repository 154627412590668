import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router';
import { windowMaxWidth } from '../ui/Layout/breakpoints';
import DefaultLogo from './DefaultLogo.png';

const LogoWrapper = styled.div`
  display: flex;
  width: 25%;

  @media ${windowMaxWidth.splitScreen} {
    justify-content: center;
    width: calc(100% - 60px);
  }
`;

const LogoLink = styled(Link)`
  align-items: center;
  display: flex;

  &:focus,
  &:active {
    border: none;
    outline: 0;
  }
`;

const LogoIcon = styled.img`
  margin-right: 10px;
  max-height: 40px;
  max-width: 40px;
`;

const CompanyName = styled.div`
  color: ${(props) => (props.light ? 'var(--greyscale-white)' : 'var(--greyscale-blackish)')};
  display: block;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
`;

const Logo = ({ agencyLogo, agencyName, light }) => (
  <LogoWrapper className="logo-wrapper">
    <LogoLink to="/claims/list">
      <LogoIcon src={agencyLogo || DefaultLogo} alt="Agency Logo" />
      <CompanyName light={light}>{agencyName || 'Ihr Versicherungsportal'}</CompanyName>
    </LogoLink>
  </LogoWrapper>
);

export default Logo;
