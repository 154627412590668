import styled from 'styled-components';
import { windowMaxWidth } from '../breakpoints';

const NAVIGATION_HEIGHT = 60;
const HEADER_HEIGHT = 101; // 100px + 1px border
const FOOTER_HEIGHT = 80;
const FOOTER_HEIGHT_STANDALONE = 90;
const WARNING_HEIGHT = 40;
const WARNING_HEIGHT_MOBILE = 52;
const RESERVED_HEIGHT = NAVIGATION_HEIGHT + HEADER_HEIGHT + FOOTER_HEIGHT;
const RESERVED_HEIGHT_WITH_WARNING = RESERVED_HEIGHT + WARNING_HEIGHT;

const RESERVED_HEIGHT_MOBILE = HEADER_HEIGHT + FOOTER_HEIGHT;
const RESERVED_HEIGHT_WITH_WARNING_MOBILE = RESERVED_HEIGHT_MOBILE + WARNING_HEIGHT_MOBILE;

const RESERVED_HEIGHT_STANDALONE = HEADER_HEIGHT + FOOTER_HEIGHT_STANDALONE;
const RESERVED_HEIGHT_WITH_WARNING_STANDALONE = HEADER_HEIGHT + FOOTER_HEIGHT_STANDALONE + WARNING_HEIGHT_MOBILE;

// Styles for the WorklistMessagesList component
const WorklistMessagesWrapper = styled.div`
  background-color: var(--secondary-whisper);
  display: flex;
  flex-direction: column;
`;

const WorklistMessagesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100dvh - ${(props) => (props.$hasWarning ? RESERVED_HEIGHT_WITH_WARNING : RESERVED_HEIGHT)}px);
  overflow: auto;
  padding-bottom: 40px;
  scrollbar-color: rgba(136 136 136 / 30%) transparent;
  scrollbar-width: thin;

  @media (${windowMaxWidth.mobile}) {
    height: calc(
      100dvh - ${(props) => (props.$hasWarning ? RESERVED_HEIGHT_WITH_WARNING_MOBILE : RESERVED_HEIGHT_MOBILE)}px
    );
  }

  @media (display-mode: standalone) {
    height: calc(
      100dvh -
        ${(props) => (props.$hasWarning ? RESERVED_HEIGHT_WITH_WARNING_STANDALONE : RESERVED_HEIGHT_STANDALONE)}px
    );
  }
`;

const WorklistMessagesListOverlay = styled.div`
  background: linear-gradient(180deg, rgba(245 246 250 / 0%) 0%, var(--secondary-whisper) 100%);
  bottom: 80px;
  display: flex;
  height: 40px;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 1;
`;

// Styles for the WorklistMessage component

const WorklistMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 40px;

  &.message-sent {
    flex-direction: row-reverse;
    padding-left: 40px;
    padding-right: 0;
  }

  &:not(.message-system) {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  &.message-system {
    padding-bottom: 5px;
    padding-top: 5px;
  }

  &.worklist-messages-list-wrapper {
    border-bottom: 1px solid #e9eaed;
    padding-top: 20px;
  }
`;

const WorklistMessageContent = styled.div`
  background-color: var(--greyscale-white);
  color: var(--greyscale-blackish);
  flex: auto;
  max-width: calc(100% - 40px);
  padding: 10px 15px;

  &:not(.message-system) {
    border: 1px solid var(--greyscale-dark-white);
    border-radius: var(--border-radius-regular);
  }

  &.active:not(.message-system) {
    border-color: var(--primary-oqio-blue);
  }

  &.message-system {
    background-color: unset;
    border-bottom: 1px solid var(--greyscale-light-grey);
    padding: 15px;
  }
`;

const WorklistMessageHeader = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  font-size: var(--font-size-small);
  justify-content: space-between;
  line-height: var(--font-line-height-small);
`;

const WorklistMessageSubHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-small);
  justify-content: space-between;
  line-height: var(--font-line-height-small);
  margin-bottom: 10px;
`;

const WorklistMessageTitle = styled.div`
  display: flex;
  font-weight: var(--font-weight-semi-bold);

  &.active {
    color: var(--primary-oqio-blue);
  }
`;

const WorklistMessageRecipients = styled.div`
  color: var(--greyscale-shade);
  display: flex;
  word-break: break-all;
`;

const WorklistMessageCreator = styled.div`
  color: var(--greyscale-blackish);
  display: flex;
  flex-wrap: wrap;

  &.active {
    color: var(--primary-oqio-blue);
  }
`;

const WorklistMessageCreatorMain = styled.div`
  display: flex;
  font-weight: var(--font-weight-semi-bold);
  margin-right: 5px;
  word-break: break-all;
`;

const WorklistMessageCreatorUser = styled.div`
  display: flex;
  word-break: break-all;
`;

const WorklistMessageCreatorSystem = styled.div`
  color: var(--greyscale-shade);
  display: flex;
  word-break: break-all;
`;

const WorklistMessageHeaderDate = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 1 0 150px;
  flex-direction: column;

  &.active {
    color: var(--primary-oqio-blue);
    font-weight: var(--font-weight-semi-bold);
  }
`;

const WorklistMessageDescription = styled.p`
  font-size: var(--font-size-medium);
  line-height: var(--font-line-height-regular);
  margin: 0;
  white-space: break-spaces;
  word-break: break-word;
`;

const WorklistMessageSystemDescription = styled.p`
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  margin: 0;
`;

const WorklistMessageDescriptionList = styled.ul`
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  margin: 0;
  padding-left: 15px;
`;

const WorklistMessageDescriptionListItem = styled.li`
  list-style: initial;
  white-space: pre-wrap;
  word-break: break-word;
`;

// Styles for the WorklistMessage reminder

const WorklistMessageReminderWrapper = styled.div`
  border-left: 2px solid var(--primary-oqio-blue);
  color: var(--greyscale-blackish);
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  margin-bottom: 10px;
  margin-left: -15px;
  padding-left: 15px;
`;

const WorklistMessageReminderDate = styled.div`
  color: var(--primary-oqio-blue);
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weight-semi-bold);
`;

const WorklistMessageReminderInfo = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;
`;

// Styles for the WorklistMessage attachments

const WorklistMessageAttachmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const WorklistMessageFile = styled.div`
  display: block;
  height: 24px;
`;

const WorklistMessageAttachmentName = styled.div`
  align-items: center;
  color: var(--greyscale-shade);
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);

  &:hover {
    color: var(--primary-oqio-blue);

    svg {
      path {
        stroke: var(--primary-oqio-blue);
      }
    }
  }

  @media ${windowMaxWidth.mobile} {
    display: block !important;
    max-width: 26ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

// Styles for the WorklistMessage action buttons

const WorklistMessageItemActionsButtons = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 40px;
  flex-direction: column;
  padding: 15px 5px;
`;

const WorklistMessageItemActionsAvatar = styled.div`
  background-color: var(--greyscale-blackish);
  border-radius: var(--border-radius-circle);
  display: flex;
  height: 30px;
  width: 30px;
`;

const WorklistMessageItemActiveToggleButton = styled.button`
  align-items: center;
  background-color: unset;
  border: none;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-bottom: 3px;
  padding: 0;
  position: relative;
  transition: all 0.2s;
  width: 20px;

  svg {
    height: 16px;
    width: 16px;

    circle {
      stroke: var(--greyscale-concrete);
    }
  }

  &:hover {
    cursor: pointer;

    svg {
      circle {
        stroke: var(--primary-oqio-blue-hover);
      }
    }
  }

  &.active {
    svg {
      fill: var(--primary-oqio-blue);

      circle {
        stroke: var(--primary-oqio-blue);
      }
    }

    &:hover {
      transition: all 0.1s;

      svg {
        fill: var(--primary-oqio-blue-hover);

        circle {
          stroke: var(--primary-oqio-blue-hover);
        }
      }
    }
  }
`;

const WorklistMessageItemReminderButton = styled.button`
  align-items: center;
  background-color: unset;
  border: none;
  display: flex;
  height: 20px;
  justify-content: center;
  padding: 0;
  position: relative;
  transition: all 0.2s;
  width: 20px;

  svg {
    height: 18px;
    width: 18px;

    path {
      stroke: var(--greyscale-concrete);
    }
  }

  &.active {
    svg {
      path {
        stroke: none;
        fill: var(--primary-oqio-blue);
      }
    }

    &:hover {
      cursor: pointer;

      svg {
        path {
          fill: var(--primary-oqio-blue-hover);
        }
      }
    }
  }

  &:not(.active) {
    &:hover {
      cursor: pointer;

      svg {
        path {
          stroke: var(--primary-oqio-blue-hover);
        }
      }
    }
  }
`;

const WorklistMessageItemReminderButtonActive = styled.button`
  align-items: center;
  background-color: unset;
  border: none;
  display: flex;
  height: 20px;
  justify-content: center;
  padding: 0;
  width: 20px;

  div {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  svg {
    path {
      fill: var(--primary-oqio-blue);
    }
  }
`;

// Styles for the WorklistMessageFooter component

const WorklistMessagesFooterWrapper = styled.div`
  background-color: var(--greyscale-white);
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 20px;

  @media (display-mode: standalone) {
    padding: 20px 20px 30px;
    height: 90px;
  }
`;

const WorklistMessagesFooterFilterWrapper = styled.div`
  align-items: center;
  color: var(--primary-oqio-blue);
  display: flex;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-regular);
`;

const WorklistMessagesFooterFilterIcon = styled.div`
  display: flex;
  height: 20px;
  margin-right: 7px;
  width: 20px;

  &:not(.active) {
    svg {
      path {
        stroke: var(--primary-oqio-blue);
      }
    }
  }

  &.active {
    svg {
      path {
        fill: var(--primary-oqio-blue);
      }
    }
  }
`;

const WorklistMessagesFooterFilterLabel = styled.div`
  display: flex;
`;

const WorklistMessagesFooterFilterLabelTitle = styled.div`
  display: flex;

  @media ${windowMaxWidth.mobile} {
    display: none;
  }
`;

export const WorklistMessagesListLayout = {
  WorklistMessagesWrapper,
  WorklistMessagesListWrapper,
  WorklistMessagesListOverlay,
  WorklistMessageWrapper,
  WorklistMessageContent,
  WorklistMessageHeader,
  WorklistMessageSubHeader,
  WorklistMessageTitle,
  WorklistMessageRecipients,
  WorklistMessageCreator,
  WorklistMessageCreatorMain,
  WorklistMessageCreatorUser,
  WorklistMessageCreatorSystem,
  WorklistMessageHeaderDate,
  WorklistMessageDescription,
  WorklistMessageSystemDescription,
  WorklistMessageDescriptionList,
  WorklistMessageDescriptionListItem,
  WorklistMessageReminderWrapper,
  WorklistMessageReminderDate,
  WorklistMessageReminderInfo,
  WorklistMessageAttachmentsWrapper,
  WorklistMessageFile,
  WorklistMessageAttachmentName,
  WorklistMessageItemActionsButtons,
  WorklistMessageItemActionsAvatar,
  WorklistMessageItemActiveToggleButton,
  WorklistMessageItemReminderButton,
  WorklistMessageItemReminderButtonActive,
  WorklistMessagesFooterWrapper,
  WorklistMessagesFooterFilterWrapper,
  WorklistMessagesFooterFilterIcon,
  WorklistMessagesFooterFilterLabel,
  WorklistMessagesFooterFilterLabelTitle
};
