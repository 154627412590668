const actions = {
  GET_WORKLIST_CLAIMS: 'GET_WORKLIST_CLAIMS',
  GET_WORKLIST_CLAIMS_SUCCESS: 'GET_WORKLIST_CLAIMS_SUCCESS',
  GET_WORKLIST_CLAIMS_ERROR: 'GET_WORKLIST_CLAIMS_ERROR',
  GET_WORKLIST_CLAIM_MESSAGES: 'GET_WORKLIST_CLAIM_MESSAGES',
  GET_WORKLIST_CLAIM_MESSAGES_SUCCESS: 'GET_WORKLIST_CLAIM_MESSAGES_SUCCESS',
  GET_WORKLIST_CLAIM_MESSAGES_ERROR: 'GET_WORKLIST_CLAIM_MESSAGES_ERROR',
  UPDATE_WORKLIST_CLAIM_MESSAGE: 'UPDATE_WORKLIST_CLAIM_MESSAGE',
  UPDATE_WORKLIST_CLAIM_MESSAGE_SUCCESS: 'UPDATE_WORKLIST_CLAIM_MESSAGE_SUCCESS',
  UPDATE_WORKLIST_CLAIM_MESSAGE_ERROR: 'UPDATE_WORKLIST_CLAIM_MESSAGE_SUCCESS',
  CREATE_WORKLIST_CLAIM_MESSAGE: 'CREATE_WORKLIST_CLAIM_MESSAGE',
  CREATE_WORKLIST_CLAIM_MESSAGE_SUCCESS: 'CREATE_WORKLIST_CLAIM_MESSAGE_SUCCESS',
  CREATE_WORKLIST_CLAIM_MESSAGE_ERROR: 'CREATE_WORKLIST_CLAIM_MESSAGE_ERROR',
  CLEAR_WORKLIST_CLAIM_MESSAGES: 'CLEAR_WORKLIST_CLAIM_MESSAGES',
  GET_CLAIM_MESSAGE_RECIPIENT_ORGANIZATIONS: 'GET_CLAIM_MESSAGE_RECIPIENT_ORGANIZATIONS',
  GET_CLAIM_MESSAGE_RECIPIENT_ORGANIZATIONS_SUCCESS: 'GET_CLAIM_MESSAGE_RECIPIENT_ORGANIZATIONS_SUCCESS',
  GET_CLAIM_MESSAGE_RECIPIENT_ORGANIZATIONS_ERROR: 'GET_CLAIM_MESSAGE_RECIPIENT_ORGANIZATIONS_ERROR',
  GET_CLAIM_ACTIVE_MESSAGES_COUNT: 'GET_CLAIM_ACTIVE_MESSAGES_COUNT',
  GET_CLAIM_ACTIVE_MESSAGES_COUNT_ERROR: 'GET_CLAIM_ACTIVE_MESSAGES_COUNT_ERROR',
  GET_CLAIM_ACTIVE_MESSAGES_COUNT_SUCCESS: 'GET_CLAIM_ACTIVE_MESSAGES_COUNT_SUCCESS',
  GET_CLAIMS_LIST_ACTIVE_MESSAGES_COUNT: 'GET_CLAIMS_LIST_ACTIVE_MESSAGES_COUNT',
  GET_CLAIMS_LIST_ACTIVE_MESSAGES_COUNT_ERROR: 'GET_CLAIMS_LIST_ACTIVE_MESSAGES_COUNT_ERROR',
  GET_CLAIMS_LIST_ACTIVE_MESSAGES_COUNT_SUCCESS: 'GET_CLAIMS_LIST_ACTIVE_MESSAGES_COUNT_SUCCESS',
  SET_WORKLIST_HEADER_ACTIVE_MESSAGES_COUNT: 'SET_WORKLIST_HEADER_ACTIVE_MESSAGES_COUNT',
  SET_WORKLIST_CLAIM_MESSAGES_FILTER: 'SET_WORKLIST_CLAIM_MESSAGES_FILTER',
  SET_WORKLIST_CLAIM_MESSAGES_FILTER_TO_DEFAULT: 'SET_WORKLIST_CLAIM_MESSAGES_FILTER_TO_DEFAULT',
  SET_IS_CREATE_MESSAGE_TOUCHED: 'SET_IS_CREATE_MESSAGE_TOUCHED',
  CLEAR_IS_CREATE_MESSAGE_TOUCHED: 'CLEAR_IS_CREATE_MESSAGE_TOUCHED',
  SET_NEW_MESSAGE_ATTACHMENTS: 'SET_NEW_MESSAGE_ATTACHMENTS',
  CLEAR_NEW_MESSAGE_ATTACHMENTS: 'CLEAR_NEW_MESSAGE_ATTACHMENTS',
  SET_IS_NEW_MESSAGE_ATTACHMENTS_UPLOAD_LOADING: 'SET_IS_NEW_MESSAGE_ATTACHMENTS_UPLOAD_LOADING',
  OPEN_WORKLIST_CLAIM_MESSAGES_FILTER: 'OPEN_WORKLIST_CLAIM_MESSAGES_FILTER',
  CLOSE_WORKLIST_CLAIM_MESSAGES_FILTER: 'CLOSE_WORKLIST_CLAIM_MESSAGES_FILTER',
  getWorklistClaims: (params) => ({
    type: actions.GET_WORKLIST_CLAIMS,
    params
  }),
  getWorklistClaimsSuccess: (claims) => ({
    type: actions.GET_WORKLIST_CLAIMS_SUCCESS,
    claims
  }),
  getWorklistClaimsError: () => ({
    type: actions.GET_WORKLIST_CLAIMS_ERROR
  }),
  getWorklistClaimMessages: (claimId, params) => ({
    type: actions.GET_WORKLIST_CLAIM_MESSAGES,
    claimId,
    params
  }),
  getWorklistClaimMessagesSuccess: (messages) => ({
    type: actions.GET_WORKLIST_CLAIM_MESSAGES_SUCCESS,
    messages
  }),
  getWorklistClaimMessagesError: () => ({
    type: actions.GET_WORKLIST_CLAIM_MESSAGES_ERROR
  }),
  updateWorklistClaimMessage: (claimId, message) => ({
    type: actions.UPDATE_WORKLIST_CLAIM_MESSAGE,
    claimId,
    message
  }),
  updateWorklistClaimMessageSuccess: (message) => ({
    type: actions.UPDATE_WORKLIST_CLAIM_MESSAGE_SUCCESS,
    message
  }),
  updateWorklistClaimMessageError: () => ({
    type: actions.UPDATE_WORKLIST_CLAIM_MESSAGE_ERROR
  }),
  createWorklistClaimMessage: (claimId, messageData, callback) => ({
    type: actions.CREATE_WORKLIST_CLAIM_MESSAGE,
    claimId,
    messageData,
    callback
  }),
  createWorklistClaimMessageSuccess: (message) => ({
    type: actions.CREATE_WORKLIST_CLAIM_MESSAGE_SUCCESS,
    message
  }),
  createWorklistClaimMessageError: () => ({
    type: actions.CREATE_WORKLIST_CLAIM_MESSAGE_ERROR
  }),
  clearWorklistClaimMessages: () => ({
    type: actions.CLEAR_WORKLIST_CLAIM_MESSAGES
  }),
  getMessageRecipientOrganizations: (claimId) => ({
    type: actions.GET_CLAIM_MESSAGE_RECIPIENT_ORGANIZATIONS,
    claimId
  }),
  getMessageRecipientOrganizationsSuccess: (organizations) => ({
    type: actions.GET_CLAIM_MESSAGE_RECIPIENT_ORGANIZATIONS_SUCCESS,
    organizations
  }),
  getMessageRecipientOrganizationsError: () => ({
    type: actions.GET_CLAIM_MESSAGE_RECIPIENT_ORGANIZATIONS_ERROR
  }),
  getClaimActiveMessagesCount: (claimId) => ({
    type: actions.GET_CLAIM_ACTIVE_MESSAGES_COUNT,
    claimId
  }),
  getClaimActiveMessagesCountSuccess: (count) => ({
    type: actions.GET_CLAIM_ACTIVE_MESSAGES_COUNT_SUCCESS,
    count
  }),
  getClaimActiveMessagesCountError: () => ({
    type: actions.GET_CLAIM_ACTIVE_MESSAGES_COUNT_ERROR
  }),
  getActiveMessagesCount: () => ({
    type: actions.GET_CLAIMS_LIST_ACTIVE_MESSAGES_COUNT
  }),
  getActiveMessagesCountSuccess: (count) => ({
    type: actions.GET_CLAIMS_LIST_ACTIVE_MESSAGES_COUNT_SUCCESS,
    count
  }),
  getActiveMessagesCountError: () => ({
    type: actions.GET_CLAIMS_LIST_ACTIVE_MESSAGES_COUNT_ERROR
  }),
  setWorklistHeaderActiveMessagesCount: (count) => ({
    type: actions.SET_WORKLIST_HEADER_ACTIVE_MESSAGES_COUNT,
    count
  }),
  setWorklistClaimMessagesFilter: (filter) => ({
    type: actions.SET_WORKLIST_CLAIM_MESSAGES_FILTER,
    filter
  }),
  setWorklistClaimMessagesFilterToDefault: () => ({
    type: actions.SET_WORKLIST_CLAIM_MESSAGES_FILTER_TO_DEFAULT
  }),
  setIsCreateMessageTouched: (isTouched) => ({
    type: actions.SET_IS_CREATE_MESSAGE_TOUCHED,
    isTouched
  }),
  clearIsCreateMessageTouched: () => ({
    type: actions.CLEAR_IS_CREATE_MESSAGE_TOUCHED
  }),
  setNewMessageAttachments: (attachments) => ({
    type: actions.SET_NEW_MESSAGE_ATTACHMENTS,
    attachments
  }),
  clearNewMessageAttachments: () => ({
    type: actions.CLEAR_NEW_MESSAGE_ATTACHMENTS
  }),
  setIsNewMessageAttachmentsUploadLoading: (isLoading) => ({
    type: actions.SET_IS_NEW_MESSAGE_ATTACHMENTS_UPLOAD_LOADING,
    isLoading
  }),
  openWorklistClaimMessagesFilter: () => ({
    type: actions.OPEN_WORKLIST_CLAIM_MESSAGES_FILTER
  }),
  closeWorklistClaimMessagesFilter: () => ({
    type: actions.CLOSE_WORKLIST_CLAIM_MESSAGES_FILTER
  })
};

export default actions;
