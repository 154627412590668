import React from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';
import { MENU_ITEMS } from '../../consts/consts';
import checkUserRights from '../../helpers/checkUserUIRights';
import { isMobile } from '../../helpers/utility';

const MenuItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.span`
  align-items: center;
  background: ${({ $isActive }) => ($isActive ? 'var(--greyscale-porcelain)' : 'none')};
  box-shadow: ${({ $isActive }) => ($isActive ? 'inset -4px 0 0px 0 var(--greyscale-blackish)' : 'none')};
  color: ${(props) => (props.$isActive ? 'var(--greyscale-blackish)' : 'var(--greyscale-shade)')};
  cursor: default;
  display: flex;
  font-size: var(--font-size-regular);
  font-weight: ${(props) => (props.$isActive ? 'var(--font-weight-medium)' : 'var(--font-weight-regular)')};
  height: 55px;
  line-height: var(--font-line-height-regular);
  padding: 0 30px;

  &:hover {
    background: var(--greyscale-porcelain);
    color: var(--greyscale-blackish);
  }
`;

const MenuLink = styled(Link)`
  align-items: center;
  color: ${(props) => (props.$isActive ? 'var(--greyscale-blackish)' : 'var(--greyscale-shade)')};
  display: flex;
  height: 100%;
  width: 100%;

  &:hover {
    color: var(--greyscale-blackish);
  }
`;

const HeaderMenuMobileMenuItems = ({ path, userRights, handleDrawerToggle }) => {
  const mainRootDividerIndex = path.indexOf('/', path.indexOf('/') + 1);
  const mainRoot = mainRootDividerIndex < 0 ? path : path.substring(0, mainRootDividerIndex);

  // Filter menu items only if the view is mobile
  const menuItems = isMobile ? MENU_ITEMS.filter((item) => item.hideOnMobile !== true) : MENU_ITEMS;

  const renderedMenuItems = menuItems.map((item) => {
    let isActive = item.link.includes(mainRoot);
    if (!isActive && item.alternativeLink) {
      isActive = item.alternativeLink.includes(mainRoot);
    }

    const handleClickMenuItem = () => {
      handleDrawerToggle(false);
    };

    return (
      checkUserRights(userRights, item.accessRights) && (
        <MenuItem key={item.key} $isActive={isActive} onClick={handleClickMenuItem}>
          <MenuLink $isActive={isActive} to={item.link}>
            {item.label}
          </MenuLink>
        </MenuItem>
      )
    );
  });

  return <MenuItemsWrapper>{renderedMenuItems}</MenuItemsWrapper>;
};

export default HeaderMenuMobileMenuItems;
