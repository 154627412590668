import { all, takeEvery, put, call } from 'redux-saga/effects';
import renderNotification from '../../helpers/notifications/renderNotification';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';
import {
  onLoadHousingStocks,
  onLoadClaimsHousingStocks,
  onCreateHousingStock,
  onUpdateHousingStock,
  onDeleteHousingStock,
  onLoadPoliciesHousingStocks
} from './apiCalls';
import actions from './actions';

const { housing_stock_create_success, housing_stock_delete_success } = NOTIFICATION_MESSAGES;

export function* loadHousingStocks() {
  try {
    const response = yield call(onLoadHousingStocks);
    if (response.data) {
      yield put(actions.getHousingStocksSuccess(response.data));
    } else {
      yield put(actions.getHousingStocksError());
    }
  } catch {
    yield put(actions.getHousingStocksError());
  }
}

export function* loadClaimsHousingStocks() {
  try {
    const response = yield call(onLoadClaimsHousingStocks);
    if (response.data) {
      yield put(actions.getClaimsHousingStocksSuccess(response.data));
    } else {
      yield put(actions.getClaimsHousingStocksError());
    }
  } catch {
    yield put(actions.getClaimsHousingStocksError());
  }
}

export function* loadPoliciesHousingStocks() {
  try {
    const response = yield call(onLoadPoliciesHousingStocks);
    if (response.data) {
      yield put(actions.getPoliciesHousingStocksSuccess(response.data));
    } else {
      yield put(actions.getPoliciesHousingStocksError());
    }
  } catch {
    yield put(actions.getPoliciesHousingStocksError());
  }
}

export function* createHousingStock({ housingStock }) {
  try {
    // the API expects an array of housingStocks
    const _housingStocks = [housingStock];
    const response = yield call(onCreateHousingStock, _housingStocks);
    if (response.data) {
      yield all([
        put(actions.createHousingStockSuccess()),
        put(actions.getHousingStocks()),
        put(actions.getClaimsHousingStocks())
      ]);
      renderNotification({ type: 'success', message: housing_stock_create_success });
    } else {
      yield put(actions.createHousingStockError());
    }
  } catch {
    yield put(actions.createHousingStockError());
  }
}

export function* updateHousingStockSaga({ housingStock }) {
  try {
    const response = yield call(onUpdateHousingStock, housingStock.id, housingStock);
    if (response.data) {
      yield all([
        put(actions.updateHousingStockSuccess()),
        put(actions.getHousingStocks()),
        put(actions.getClaimsHousingStocks())
      ]);
    } else {
      yield put(actions.updateHousingStockError());
    }
  } catch {
    yield put(actions.updateHousingStockError());
  }
}

export function* deleteHousingStockSaga({ housingStockId }) {
  try {
    const response = yield call(onDeleteHousingStock, housingStockId);
    if (response.status === 200) {
      yield all([
        put(actions.deleteHousingStockSuccess()),
        put(actions.getHousingStocks()),
        put(actions.getClaimsHousingStocks())
      ]);
      renderNotification({ type: 'success', message: housing_stock_delete_success });
    } else {
      yield put(actions.deleteHousingStockError());
    }
  } catch {
    yield put(actions.deleteHousingStockError());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_HOUSING_STOCKS, loadHousingStocks),
    takeEvery(actions.LOAD_CLAIMS_HOUSING_STOCKS, loadClaimsHousingStocks),
    takeEvery(actions.GET_POLICIES_HOUSING_STOCKS, loadPoliciesHousingStocks),
    takeEvery(actions.CREATE_HOUSING_STOCK, createHousingStock),
    takeEvery(actions.UPDATE_HOUSING_STOCK, updateHousingStockSaga),
    takeEvery(actions.DELETE_HOUSING_STOCK, deleteHousingStockSaga)
  ]);
}
