import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  createHousingStockLoading: false,
  createHousingStockError: false,
  updateHousingStockLoading: false,
  updateHousingStockError: false,
  deleteHousingStockLoading: false,
  deleteHousingStockError: false,
  housingStocks: [],
  housingStocksLoading: false,
  housingStocksError: false,
  claimsHousingStocks: [],
  claimsHousingStocksLoading: false,
  claimsHousingStocksError: false,
  policiesHousingStocks: [],
  policiesHousingStocksLoading: false,
  policiesHousingStocksError: false
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.CREATE_HOUSING_STOCK:
      return state.set('createHousingStockLoading', true).set('createHousingStockError', false);
    case actions.CREATE_HOUSING_STOCK_SUCCESS:
      return state.set('createHousingStockLoading', false).set('createHousingStockError', false);
    case actions.CREATE_HOUSING_STOCK_ERROR:
      return state.set('createHousingStockLoading', false).set('createHousingStockError', true);

    case actions.UPDATE_HOUSING_STOCK:
      return state.set('updateHousingStockLoading', true).set('updateHousingStockError', false);
    case actions.UPDATE_HOUSING_STOCK_SUCCESS:
      return state.set('updateHousingStockLoading', false).set('updateHousingStockError', false);
    case actions.UPDATE_HOUSING_STOCK_ERROR:
      return state.set('updateHousingStockLoading', false).set('updateHousingStockError', true);

    case actions.DELETE_HOUSING_STOCK:
      return state.set('deleteHousingStockLoading', true).set('deleteHousingStockError', false);
    case actions.DELETE_HOUSING_STOCK_SUCCESS:
      return state.set('deleteHousingStockLoading', false).set('deleteHousingStockError', false);
    case actions.DELETE_HOUSING_STOCK_ERROR:
      return state.set('deleteHousingStockLoading', false).set('deleteHousingStockError', true);

    case actions.LOAD_HOUSING_STOCKS:
      return state.set('housingStocksLoading', true).set('housingStocksError', false);
    case actions.LOAD_HOUSING_STOCKS_SUCCESS:
      return state
        .set('housingStocksLoading', false)
        .set('housingStocksError', false)
        .set('housingStocks', action.housingStocks);
    case actions.LOAD_HOUSING_STOCKS_ERROR:
      return state.set('housingStocksLoading', false).set('housingStocksError', true);

    case actions.LOAD_CLAIMS_HOUSING_STOCKS:
      return state.set('claimsHousingStocksLoading', true).set('claimsHousingStocksError', false);
    case actions.LOAD_CLAIMS_HOUSING_STOCKS_SUCCESS:
      return state
        .set('claimsHousingStocksLoading', false)
        .set('claimsHousingStocksError', false)
        .set('claimsHousingStocks', action.housingStocks);
    case actions.LOAD_CLAIMS_HOUSING_STOCKS_ERROR:
      return state.set('claimsHousingStocksLoading', false).set('claimsHousingStocksError', true);

    case actions.GET_POLICIES_HOUSING_STOCKS:
      return state.set('policiesHousingStocksLoading', true).set('policiesHousingStocksError', false);
    case actions.GET_POLICIES_HOUSING_STOCKS_SUCCESS:
      return state
        .set('policiesHousingStocksLoading', false)
        .set('policiesHousingStocksError', false)
        .set('policiesHousingStocks', action.housingStocks);
    case actions.GET_POLICIES_HOUSING_STOCKS_ERROR:
      return state.set('policiesHousingStocksLoading', false).set('policiesHousingStocksError', true);

    case actions.CLEAR_HOUSING_STOCKS_ERRORS:
      return state.set('createHousingStockError', false).set('updateHousingStockError', false);

    default:
      return state;
  }
};

export default reducer;
