import React from 'react';

const IconHouse = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m3 8 7-6 7 6v9a1 1 0 0 1-1 1h-4v-4a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v4H4a1 1 0 0 1-1-1V8z"
      stroke="#111"
      strokeWidth="1.5"
    />
  </svg>
);

export default IconHouse;
