import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onLoadPoliciesToRisks = async (params) =>
  axios
    .get(apiUrls.policiesToRisksBaseUrl, { params })
    .then((res) => res)
    .catch((error) => error);

const onCreatePoliciesToRisks = async (policiesToRisks) =>
  axios
    .post(apiUrls.policiesToRisksBaseUrl, policiesToRisks)
    .then((res) => res)
    .catch((error) => error);

const onDeleteManyPoliciesToRisks = async (params) =>
  axios
    .post(`${apiUrls.policiesToRisksBaseUrl}/delete-many`, params)
    .then((res) => res)
    .catch((error) => error);

export { onLoadPoliciesToRisks, onCreatePoliciesToRisks, onDeleteManyPoliciesToRisks };
