import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import actions from './actions';
import {
  onGetWorklistRisks,
  onGetWorklistRiskMessages,
  onCreateWorklistRiskMessage,
  onGetRisksActiveMessagesCount,
  onGetRiskActiveMessagesCount,
  onUpdateWorklistRiskMessage,
  onGetRiskMessageRecipientOrganizations
} from './apiCalls';

export function* getWorklistRisksSaga({ params }) {
  try {
    const response = yield call(onGetWorklistRisks, params);
    if (response.status === 200) {
      yield put(actions.getWorklistRisksSuccess(response.data));
    } else {
      yield put(actions.getWorklistRisksError());
    }
  } catch (error) {
    yield put(actions.getWorklistRisksError());
  }
}

export function* getWorklistRiskMessagesSaga({ riskId, params }) {
  try {
    let _params = params;
    if (!_params || !_params.filter) {
      const worklistRiskMessagesFilter = yield select(getWorklistRiskMessagesFilter);
      _params = { filter: worklistRiskMessagesFilter };
    }
    const response = yield call(onGetWorklistRiskMessages, riskId, _params);

    if (response.status === 200) {
      yield put(actions.getWorklistRiskMessagesSuccess(response.data));
    }
  } catch (error) {
    yield put(actions.getWorklistRiskMessagesError());
  }
}

export function* getWorklistMessagesOnFilterChangeSaga({ filter }) {
  try {
    const risk = yield select(getCurrentRisk);
    const riskId = risk.id;
    const _params = { filter };
    yield all([put(actions.getWorklistRiskMessages(riskId, _params)), put(actions.getRiskActiveMessagesCount(riskId))]);
  } catch (error) {
    yield put(actions.getWorklistRiskMessagesError());
  }
}

export function* getWorklistRiskMessagesSuccessSaga({ messages }) {
  const activeMessages = messages.filter((message) => message.is_active);
  yield put(actions.setWorklistHeaderActiveMessagesCount(activeMessages.length));
}

export function* getMessageRecipientOrganizationsSaga({ riskId }) {
  try {
    const response = yield call(onGetRiskMessageRecipientOrganizations, riskId);
    if (response.status === 200) {
      yield put(actions.getRiskMessageRecipientOrganizationsSuccess(response.data));
    } else {
      yield put(actions.getRiskMessageRecipientOrganizationsError());
    }
  } catch (error) {
    yield put(actions.getRiskMessageRecipientOrganizationsError());
  }
}

export function* getActiveMessagesCountSaga() {
  try {
    const response = yield call(onGetRisksActiveMessagesCount);
    if (response.data) {
      yield put(actions.getActiveMessagesCountSuccess(response.data.count));
    } else {
      yield put(actions.getActiveMessagesCountError(response.data));
    }
  } catch (error) {
    yield put(actions.getActiveMessagesCountError(error));
  }
}

export function* getRiskActiveMessagesCountSaga({ riskId }) {
  try {
    const response = yield call(onGetRiskActiveMessagesCount, riskId);
    if (response.data) {
      yield put(actions.getRiskActiveMessagesCountSuccess(response.data.count));
    } else {
      yield put(actions.getRiskActiveMessagesCountError(response.data));
    }
  } catch (error) {
    yield put(actions.getRiskActiveMessagesCountError(error));
  }
}

export function* createWorklistRiskMessageSaga({ riskId, messageData, callback }) {
  try {
    const response = yield call(onCreateWorklistRiskMessage, riskId, messageData);
    if (response.status === 200) {
      yield put(actions.createWorklistRiskMessageSuccess());
      if (typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.createWorklistRiskMessageError());
    }
  } catch (error) {
    yield put(actions.createWorklistRiskMessageError());
  }
}

export function* updateWorklistRiskMessageSaga({ riskId, message }) {
  try {
    const response = yield call(onUpdateWorklistRiskMessage, riskId, message);
    if (response.status === 200) {
      yield put(actions.updateWorklistRiskMessageSuccess());
    } else {
      yield put(actions.updateWorklistRiskMessageError());
    }
  } catch (error) {
    yield put(actions.updateWorklistRiskMessageError());
  }
}

const getCurrentRisk = (state) => state.Risks.get('risk');
const getWorklistRiskMessagesFilter = (state) => state.WorklistRisks.get('worklistRiskMessagesFilter');

function* reloadWorklistRiskMessages() {
  const risk = yield select(getCurrentRisk);
  const worklistRiskMessagesFilter = yield select(getWorklistRiskMessagesFilter);
  yield all([
    put(actions.getWorklistRiskMessages(risk?.id, { filter: worklistRiskMessagesFilter })),
    put(actions.getRiskActiveMessagesCount(risk?.id))
  ]);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_RISKS_LIST_ACTIVE_MESSAGES_COUNT, getActiveMessagesCountSaga),
    takeEvery(actions.GET_RISK_ACTIVE_MESSAGES_COUNT, getRiskActiveMessagesCountSaga),
    takeEvery(actions.GET_WORKLIST_RISKS, getWorklistRisksSaga),
    takeEvery(actions.GET_WORKLIST_RISKS_SUCCESS, getActiveMessagesCountSaga),
    takeEvery(actions.GET_WORKLIST_RISK_MESSAGES, getWorklistRiskMessagesSaga),
    takeEvery(actions.GET_WORKLIST_RISK_MESSAGES_SUCCESS, getWorklistRiskMessagesSuccessSaga),
    takeEvery(actions.GET_RISK_MESSAGE_RECIPIENT_ORGANIZATIONS, getMessageRecipientOrganizationsSaga),
    takeEvery(actions.CREATE_WORKLIST_RISK_MESSAGE, createWorklistRiskMessageSaga),
    takeEvery(actions.CREATE_WORKLIST_RISK_MESSAGE_SUCCESS, reloadWorklistRiskMessages),
    takeEvery(actions.UPDATE_WORKLIST_RISK_MESSAGE, updateWorklistRiskMessageSaga),
    takeEvery(actions.UPDATE_WORKLIST_RISK_MESSAGE_SUCCESS, reloadWorklistRiskMessages),
    takeEvery(actions.SET_WORKLIST_RISK_MESSAGES_FILTER, getWorklistMessagesOnFilterChangeSaga)
  ]);
}
