import axios from '../../config/axios';
import apiUrls from '../apiUrls';

const onGetClaimsActiveMessagesCount = async () =>
  axios
    .get(`${apiUrls.claimBaseUrl}/active-messages-count`)
    .then((res) => res)
    .catch((error) => error);

const onGetClaimActiveMessagesCount = async (claimId) =>
  axios
    .get(`${apiUrls.claimBaseUrl}/${claimId}/active-messages-count`)
    .then((res) => res)
    .catch((error) => error);

const onGetWorklistClaims = async (params) => {
  const worklistClaimsParams = {
    ...params,
    only_with_active_messages: true,
    sort: '-updated_at',
    paging: false
  };
  return axios
    .get(apiUrls.claimBaseUrl, { params: worklistClaimsParams })
    .then((res) => res)
    .catch((error) => error);
};

const onGetWorklistClaimMessages = async (claimId, messagesParams) => {
  const params = messagesParams || {};
  return axios
    .get(`${apiUrls.claimBaseUrl}/${claimId}/messages`, { params })
    .then((responses) => responses)
    .catch((error) => error);
};

const onGetClaimMessageRecipientOrganizations = async (claimId) => {
  return axios
    .get(`${apiUrls.claimBaseUrl}/${claimId}/message-recipient-organizations`)
    .then((response) => response)
    .catch((error) => error);
};

const onUpdateWorklistClaimMessage = async (claimId, message) => {
  const messageId = message.id;
  return axios
    .put(`${apiUrls.claimBaseUrl}/${claimId}/messages/${messageId}`, message)
    .then((res) => res)
    .catch((error) => error);
};

const onCreateWorklistClaimMessage = async (claimId, message) => {
  return axios
    .post(`${apiUrls.claimBaseUrl}/${claimId}/messages`, message)
    .then((response) => response)
    .catch((error) => error);
};

export {
  onGetClaimsActiveMessagesCount,
  onGetClaimActiveMessagesCount,
  onGetWorklistClaims,
  onGetWorklistClaimMessages,
  onGetClaimMessageRecipientOrganizations,
  onCreateWorklistClaimMessage,
  onUpdateWorklistClaimMessage
};
